.flickity-prev-next-button { background: transparent; text-align: center; padding: 0; border: 4px solid $yellow; border-radius: 0; width: 70px; height: 70px;
  &.previous { left: -40px;
    @include media($tablet-wide) { left: 0; }
  }
  &.next { right: -40px;
    @include media($tablet-wide) { right: 0; }
  }

  &:hover { background: transparent; }
  svg { width: 10px; position: static; display: inline-block; vertical-align: middle;
    path { fill: $yellow !important; }
  }

  @include media($tablet-wide) { width: 40px; height: 40px; border-width: 2px; }
}

%flickity-prev-next-button-article { @extend .flickity-prev-next-button; border: 0;
  &.previous { left: 20px; }
  &.next { right: 20px; }

  svg { width: 30px; position: static; display: inline-block; vertical-align: middle;
    path { fill: $white !important; }
  }
}
