%content { font-size: 20px;
  p { margin: 20px 0; }
  a { color: $brand; text-decoration: none;
    &:hover { text-decoration: underline; }
  }
  h2 { font-family: $title-font; font-size: em(20px); color: darken($gray, 5%); font-weight: $mediumbold; margin: -60px 0 30px 0; line-height: 1; position: relative; padding-top: 100px; z-index: -1;
    &:before { content: ""; display: block; height: 3px; background: $brand; width: 25px; display: inline-block; vertical-align: middle; margin-left: -37px; margin-right: 12px; margin-top: -5px; }
  }

  blockquote { font-size: em(22px); font-family: $title-font; font-style: italic; font-weight: $bold; color: darken($gray, 5%); position: relative; line-height: 1.4; float: right; max-width: 460px; margin: 0 -140px 0 30px;
    &:after { content: "”"; font-family: $serif; color: $purple; position: absolute; top: -30px; left: 100%; font-size: em(90px, 22px); }
    p { margin-top: 0; }

    @include media($desktop-narrow) { margin-right: 0; padding-right: 40px; font-size: em(18px); float: none; margin-left: 0; max-width: none;
      &:after { left: auto; right: 10px; top: -25px; }
    }
  }

  .alignleft { float: left; margin-right: 20px; text-align: left;
    .wp-caption-text { position: absolute; right: 100%; top: 0; max-width: 210px; width: 100%; padding-right: 30px; text-align: right; }
  }
  .alignright { float: right; margin-left: 20px; text-align: right;
    .wp-caption-text { position: absolute; left: 100%; top: 0; max-width: 210px; width: 100%; padding-left: 30px; text-align: left; }
  }
  .aligncenter { text-align: center; margin: 0 auto; }
  .alignleft, .alignright, .aligncenter {
    @include media($desktop-narrow) { float: none; margin: 0 auto; }
  }

  .wp-caption { position: relative;
    .wp-caption-text { font-style: italic; font-size: em(12px); margin: 0;
      @include media($desktop-narrow) { position: static; max-width: none; text-align: left; margin-bottom: 20px; }
    }
  }

  @include media($mobile) { font-size: 18px; }
}

.article { padding-top: 20px; margin-bottom: 50px;
  &__Ad { float: right; margin-left: 20px; margin-top: 10px; margin-bottom: 10px;
    @include media($tablet) { float: none; margin: 0; }
  }

  &__Header { text-align: center; position: relative; @include outer-container; padding: 0 10px; margin-bottom: 40px;
    @include media($tablet) { margin-bottom: 10px; }

    &--with-Image { @extend .article__Header; margin: -20px auto 0 auto; padding: 20% 50px 50px; max-width: none; background-position: center bottom; }
  }
    &__Subtitle { margin-top: 30px; font-size: 13px; text-transform: uppercase; color: $gray; font-family: $title-font; font-weight: $ultra; 
      strong { color: $black; font-weight: $ultra; }
    }
    &__Title { font-size: em(64px); font-family: $title-font; font-weight: $mediumbold; max-width: 700px; margin: 0 auto 0 auto; line-height: 1; color: inherit;
      a { text-decoration: none; color: inherit; transition: 200ms;
        &:hover { opacity: 0.8; }
      }
      span { @include dashing(68px, 5px, 0, false, $brand); }

      @each $category, $color in $brand-colors {
        &--#{$category} { @extend .article__Title;
          span { @include dashing(68px, 5px, 0, false, $color); }
        }
      }

      @include media($tablet) { font-size: em(40px); margin-bottom: 20px;
        span { background: transparent; line-height: 1; }
      }

      &--with-Image { @extend .article__Title; max-width: none;
        @include media($tablet) { font-size: 12vw !important; }
        span { background: transparent; }
      }

      &--Page { @extend .article__Title; margin: 0 auto 60px auto; font-size: em(58px); font-weight: $bold;
        span { @include dashing(62px, 5px, 0, false, $blue); }
      }
    }

    &__HeaderInfo { position: relative; @include outer-container; }
      &__Meta { @include inlineNav(false); margin: 10px auto; text-transform: uppercase; max-width: 800px; color: lighten($gray, 20%);
        li { font-family: $title-font; font-weight: $ultra; font-size: em(14px); margin: 0 10px; }
        a { color: inherit; text-transform: uppercase; text-decoration: none;
          &:hover { text-decoration: underline; }
        }
      }

      &__Lead { font-style: italic; font-size: em(22px); width: 100%; max-width: 1000px; padding: 0 250px; margin: 30px auto 10px auto; line-height: 1.3;
        @include media($tablet) { padding: 0; margin-bottom: 30px; }
        @include media($mobile) { font-size: em(18px); margin: 0; }
      }

      &__Author { position: absolute; width: 30%; top: 0; left: 10px; text-align: left;
        @include media($tablet) { position: static; @include span-columns(6); }
        @include media($mobile) { position: static; float: none; display: inline-block; width: auto; }
      }
        &__AuthorImage { display: inline-block; vertical-align: middle; border-radius: 50%; margin-right: 10px;
          img { border-radius: 50%; }
          @include media($mobile) { display: none; }
        }
        &__AuthorName { font-style: italic; display: inline-block; vertical-align: middle; line-height: 1.2; margin: 15px 0 20px 0;
          a, strong { display: block; font-style: normal; font-weight: $bold; font-family: $title-font; text-decoration: none; font-size: $large; }

          a { color: $brand;
            &:hover { color: $dgray; }
          }
          @include media($mobile) { font-size: 0;
            a, strong { font-size: 18px; }
          }
        }

        &__Share { position: absolute; width: 30%; top: 0; right: 0; text-align: right; @include inlineNav(false); fill: lighten($gray, 10%);
          @include media($tablet) { position: static; @include span-columns(6); @include omega; padding: 10px 0; }
          @include media($mobile) { display: none; }
          a { width: 55px; height: 55px; line-height: 62px; border: 2px solid lighten($gray, 40%); border-radius: 50%; display: block; text-align: center; margin: 0 10px; fill: inherit; transition: 200ms;
            &:hover { fill: $brand; border-color: $brand; }
          }
          svg { width: 22px; height: 22px; fill: inherit; vertical-align: 0; }

          &--Menu { @extend .article__Share; position: fixed; z-index: 2000; right: 75px; opacity: 0; visibility: hidden; transition: 300ms; padding: 0;
            li { border: 0; fill: $white; width: 75px; height: 75px; line-height: 75px; text-align: center; position: relative; 
              &:hover {
                & > a { background: $white; fill: $black; }
                ul { opacity: 1; visibility: visible; }
              }
            }
            a { border: 0; width: auto; height: auto; line-height: inherit; border-radius: 0; margin: 0; cursor: pointer;
              &:hover { fill: $black; background: $white; }
            }
            svg { vertical-align: -5px; width: 24px; height: 24px; }
            ul { position: absolute; top: 75px; left: 0; padding: 0; opacity: 0; visibility: hidden; transition: 300ms;
              a { background: $black; }
            }

            .is-Visible & { opacity: 1; visibility: visible; }
            &.is-Hidden { display: none; }
          }
        }

    &__Footer { text-align: center; }
      &__Like { margin: 0 0 50px 0; }

  &__Image { margin-bottom: -100px; position: relative; z-index: 0; background-size: cover; background-repeat: no-repeat; background-position: center; padding-bottom: 40%;
    img { display: none; }
    @include media($tablet) { padding: 0; height: 40vh; }
  }
  &__Wrapper { position: relative; }
  &__Intro { background: $white; max-width: $max-width; margin: 0 auto; }
  &__Container { background: $white; position: relative; z-index: 10; margin-left: -30px; margin-right: 100px; padding: 60px 160px 30px 260px;
    &--Dark { @extend .article__Container; background: transparent; padding-top: 0; }
    &--Breaker { @extend .article__Container; @include outer-container; padding: 0 260px 0 230px; }
    &--Page { @extend .article__Container; padding: 60px 5%; max-width: 800px; margin: 0 auto; background: transparent; }

    @include media($tablet-wide) { padding-left: 80px; padding-right: 80px; margin: 0; }
    @include media($tablet) { padding: 50px; margin: 0; }
    @include media($mobile) { padding: 10px; margin: 0; }
  }

    &__TOC { position: absolute; left: 30px; margin-top: 35px; opacity: 1; visibility: visible; bottom: 0; top: 0;
      ol { margin: 0; padding: 0; counter-reset: toc; padding: 10px 0; border-top: 2px solid $brand; border-bottom: 2px solid $brand; width: 160px; margin-top: 40px; margin-bottom: 100px; }
      li { display: block; counter-increment: toc; }
      a { color: lighten($gray, 20%); font-family: $title-font; font-weight: $bold; text-decoration: none; text-transform: uppercase; font-size: em(12px); line-height: 1.4; position: relative; padding: 8px 0; display: block;
        &:before { content: counter(toc) ". "; color: $brand; margin-right: 2px; }
        &:hover { color: $gray; }
      }

      @each $category, $color in $brand-colors {
        &--#{$category} { @extend .article__TOC;
          ol { border-color: $color; }
          a {
            &:before { color: $color; }
          }
        }
      }

      @include media($tablet-wide) { display: none; }
    }

    &__Content { padding-bottom: 20px;
      @extend %content;

      &--Prologue { @extend .article__Content;
        & > p:first-child { margin-top: 0;
          &:first-letter { font-size: em(80px); font-family: $title-font; float: left; line-height: 1; margin-right: 10px; font-weight: $mediumbold; margin-bottom: -10px; color: $brand; @include dashing(95px, 6px, 13px, false, $black); }
        }
      }

      @each $category, $color in $brand-colors {
        &--prologue-#{$category} { @extend .article__Content; font-family: attr(data-font);
          & > p:first-child { margin-top: 0;
            &:first-letter { font-size: em(80px); font-family: $title-font; float: left; line-height: 1; margin-right: 10px; font-weight: $mediumbold; margin-bottom: -10px; color: $color; @include dashing(95px, 6px, 13px, false, $black); }
          }
        }
      }

      &--without-PaddingBottom { @extend .article__Content; padding-bottom: 0; }
    }
}

.breaker { background: $white; padding: 40px 0; overflow: hidden;
  @extend %content;
  &--text-Center { @extend .breaker; text-align: center; }
  &--text-Right { @extend .breaker; text-align: right; }
  &--Dark { @extend .breaker; background: $dgray; color: $white; }
  &--Image { @extend .breaker; background: $dgray; padding: 0; }

  @include media($tablet) { padding: 0; }
}

.ruler { background: url(../images/logo_symbol.png) no-repeat center; border: 0; display: block; width: 50%; height: 30px; background-size: auto 100%; position: relative; margin: 50px auto;
  &:before, &:after { content: ""; display: block; height: 1px; width: 50%; background: lighten($gray, 40%); vertical-align: middle; position: absolute; top: 50%; right: 50%; margin-right: 40px; }
  &:after { right: auto; left: 50%; margin-left: 40px; margin-right: 0; }
}

.error404 { background: $lgray; display: flex; min-height: 100vh; min-height: calc(100vh - 150px); align-items: center; justify-content: center;
  &__Wrapper { max-width: 600px; text-align: center; @extend %content;
    h1 { font-family: $title-font; }
  }
}

// Embedded video
.embed { max-width: 800px; margin: 0 auto;
  &__Wrapper { position: relative; position: relative; padding-bottom: 56.25%; /* 16:9 */ padding-top: 25px; margin: 0 auto; }
  iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
}

.articleNav { text-align: center; border-bottom: 1px solid darken($white, 8%);  background: $white; z-index: 1000; bottom: -1px; transition: 300ms; left: 0; right: 0; padding-top: 2px; transform: translateY(100%); position: fixed; opacity: 0; visibility: hidden;
  @include media($desktop-narrow) { left: 0; right: 0; }
  &__Progress { position: absolute; top: 0; left: 0; height: 5px; width: 100%; border: none; appearance: none; color: $purple; background: none; margin: 0; background: darken($white, 15%);
    &::-webkit-progress-bar {
      background: darken($white, 15%);
    }

    &::-moz-progress-bar {
      background: darken($white, 15%);
    }

    &::-webkit-progress-value {
      background: $purple;
    }

  }
  ul, ol { margin: 0; padding: 0 10px; white-space: nowrap; overflow-y: auto; counter-reset: toc-counter; }

    li { display: inline-block; counter-increment: toc-counter;
      &:before { display: inline-block; vertical-align: middle; content: ""; background: darken($white, 15%); height: 1px; width: 30px; margin-left: 10px; }
      &:first-child {
        &:before { display: none; }
      }
      &.current-menu-item a { color: $purple; max-width: 500px; }
    }

    a { display: inline-block; text-decoration: none; padding: 0 20px; color: darken($white, 30%); font-weight: $bold; line-height: 60px; transition: all 200ms 200ms, max-width 400ms 400ms; font-family: $title-font; text-transform: uppercase; font-size: em(13px); max-width: 40px; overflow: hidden; vertical-align: middle;
      @include media($tablet-wide) { line-height: 50px; }
      &:before { content: counter(toc-counter, decimal-leading-zero); display: inline-block; margin-right: 10px; }

      &:hover { color: $purple; transition: all 200ms 0ms, max-width 400ms 400ms;
        i { color: inherit; }
      }
    }

  .is-Visible & { transform: translateY(0%); opacity: 1; visibility: visible; }
}

.gallerySlider { overflow: hidden; background: $dgray;
  .flickity-viewport { padding-bottom: 50%; position: static; }
  .flickity-prev-next-button { @extend %flickity-prev-next-button-article; }
  &__List { margin: 0; padding: 0; max-height: 620px; position: relative; }
    li { display: block; @include overlay(0.8);
      @include media($tablet) { max-width: 100vw; width: 100%; }
      &:before { transition: 500ms; }
      &.is-selected { @include overlay(0); }
    }

    img { max-width: none; vertical-align: middle; position: relative; z-index: -2;
      @include media($tablet) { width: 100%; }
    }
}
