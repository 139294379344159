// COLORS
  // $brand: #DF6253;
  // $blue: #65ACE9;
  
  $brand: #E00990;
  $yellow: #F8C51B;
  $blue: #0ACDEA;
  $purple: #8F74FF;
  $orange: #EC6350;

  $brand-colors: (Brand, $brand), (Yellow, $yellow), (Blue, $blue), (Purple, $purple), (Orange, $orange);

  $white: #FFF;
  $lgray: #F1F1F1;
  $gray: #676767;
  $dgray: #222;
  $black: #000;
  
  $success: #A7C520;
  $error: #CD2C24;
  $warning: #FFB03B;

  $facebook: #3B5997;
  $twitter: #4099FF;

// TYPE
  $font-size: 16px;
  $line-height: 1.5;
  $serif: "freight-text-pro", Georgia, serif;
  $title-font: "brandon-grotesque", Arial, sans-serif;
  $body-font: $serif;
  
  $thin: 100;
  $light: 300;
  $regular: 400;
  $mediumbold: 500;
  $semibold: 600;
  $bold: 700;
  $ultra: 900;

  $xxsmall: em(10px);
  $xsmall: em(12px);
  $small: em(14px);
  $medium: em(16px);
  $large: em(18px);
  $xlarge: em(20px);
  $xxlarge: em(24px);
  $slider: em(54px);
  $title: em(32px);
  $article: em(48px);
  $articlebody: 19px;

// DEFAULTS
  body { font: #{$font-size}/#{$line-height} $body-font; }
  img { max-width: 100%; height: auto; }
  
  .center { @include outer-container; padding-left: 10px; padding-right: 10px; }
  .clearfix { @include clearfix; }

  ::selection { background: darken($white, 20%); text-shadow: none; }