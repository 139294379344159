@charset "UTF-8";
@import url(../../assets/bower_components/normalize.css/normalize.css);
@import url(../../assets/sass/plugins/animate.css);
@import url(../../assets/sass/plugins/flickity.css);
html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

body {
  font: 16px/1.5 "freight-text-pro", Georgia, serif; }

img {
  max-width: 100%;
  height: auto; }

.center, .blockArticles__Wrapper, .categoryArticles__Wrapper {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px; }
  .center::after, .blockArticles__Wrapper::after, .categoryArticles__Wrapper::after {
    clear: both;
    content: "";
    display: table; }

.clearfix::after {
  clear: both;
  content: "";
  display: table; }

::selection {
  background: #cccccc;
  text-shadow: none; }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.symbol-blog-article, .symbol-close, .symbol-facebook, .symbol-gallery, .symbol-logo, .symbol-mail, .symbol-menu, .symbol-right, .symbol-search, .symbol-twitter, .symbol-video, .symbol-youtube {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  font-style: normal; }

svg.icon:not(.icon--full-color) circle, svg.icon:not(.icon--full-color) ellipse, svg.icon:not(.icon--full-color) g, svg.icon:not(.icon--full-color) line, svg.icon:not(.icon--full-color) path, svg.icon:not(.icon--full-color) polygon, svg.icon:not(.icon--full-color) polyline, svg.icon:not(.icon--full-color) rect, symbol.icon:not(.icon--full-color) circle, symbol.icon:not(.icon--full-color) ellipse, symbol.icon:not(.icon--full-color) g, symbol.icon:not(.icon--full-color) line, symbol.icon:not(.icon--full-color) path, symbol.icon:not(.icon--full-color) polygon, symbol.icon:not(.icon--full-color) polyline, symbol.icon:not(.icon--full-color) rect {
  fill: inherit;
  stroke: inherit; }

.symbol-blog-article {
  width: 30.648px;
  height: auto; }

.symbol-close {
  width: 54px;
  height: auto; }

.symbol-facebook {
  width: 430.113px;
  height: auto; }

.symbol-gallery {
  width: 38.356px;
  height: auto; }

.symbol-logo {
  width: 432.40002px;
  height: auto; }

.symbol-mail {
  width: 485.41101px;
  height: auto; }

.symbol-menu {
  width: 54px;
  height: auto; }

.symbol-right {
  width: 268.832px;
  height: auto; }

.symbol-search {
  width: 48.7px;
  height: auto; }

.symbol-twitter {
  width: 611.99902px;
  height: auto; }

.symbol-video {
  width: 40.018px;
  height: auto; }

.symbol-youtube {
  width: 90px;
  height: auto; }

#__bs_notify__ {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -125px !important;
  width: 250px;
  height: 50px;
  border-radius: 0 !important;
  opacity: 0.5; }

.color-Brand {
  color: #E00990; }

.color-Yellow {
  color: #F8C51B; }

.color-Blue {
  color: #0ACDEA; }

.color-Purple {
  color: #8F74FF; }

.color-Orange {
  color: #EC6350; }

body {
  background: #F1F1F1;
  padding-top: 150px; }

.flickity-prev-next-button, .gallerySlider .flickity-prev-next-button {
  background: transparent;
  text-align: center;
  padding: 0;
  border: 4px solid #F8C51B;
  border-radius: 0;
  width: 70px;
  height: 70px; }
  .flickity-prev-next-button.previous {
    left: -40px; }
    @media screen and (max-width: 960px) {
      .flickity-prev-next-button.previous {
        left: 0; } }
  .flickity-prev-next-button.next {
    right: -40px; }
    @media screen and (max-width: 960px) {
      .flickity-prev-next-button.next {
        right: 0; } }
  .flickity-prev-next-button:hover {
    background: transparent; }
  .flickity-prev-next-button svg, .gallerySlider .flickity-prev-next-button svg {
    width: 10px;
    position: static;
    display: inline-block;
    vertical-align: middle; }
    .flickity-prev-next-button svg path, .gallerySlider .flickity-prev-next-button svg path {
      fill: #F8C51B !important; }
  @media screen and (max-width: 960px) {
    .flickity-prev-next-button, .gallerySlider .flickity-prev-next-button {
      width: 40px;
      height: 40px;
      border-width: 2px; } }

.gallerySlider .flickity-prev-next-button {
  border: 0; }
  .gallerySlider .previous.flickity-prev-next-button {
    left: 20px; }
  .gallerySlider .next.flickity-prev-next-button {
    right: 20px; }
  .gallerySlider .flickity-prev-next-button svg {
    width: 30px;
    position: static;
    display: inline-block;
    vertical-align: middle; }
    .gallerySlider .flickity-prev-next-button svg path {
      fill: #FFF !important; }

@keyframes spinMenu {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes logoAppear {
  0%, 50% {
    transform: translateX(-50%);
    opacity: 0; }
  100% {
    transform: translateX(0%);
    opacity: 1; } }

.siteHeader, .siteHeader--Brand, .siteHeader--Yellow, .siteHeader--Blue, .siteHeader--Purple, .siteHeader--Orange {
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  background: #FFF;
  transition: top 0ms, background 300ms;
  color: #222;
  border-top: 5px solid #E00990; }
  .siteHeader__Wrapper {
    position: relative;
    min-height: 75px; }
  .siteHeader__Ad {
    padding: 20px 0;
    display: none; }
    .siteHeader__Ad img {
      vertical-align: middle; }
    .is-Smaller .siteHeader__Ad {
      display: none; }
    .siteHeader__Ad.is-Visible {
      display: block; }
  .siteHeader.is-Smaller, .is-Smaller.siteHeader--Brand, .is-Smaller.siteHeader--Yellow, .is-Smaller.siteHeader--Blue, .is-Smaller.siteHeader--Purple, .is-Smaller.siteHeader--Orange {
    position: fixed;
    top: -75px;
    background: #E00990;
    color: #FFF;
    padding-top: 70px; }
    @media screen and (max-width: 768px) {
      .siteHeader.is-Smaller, .is-Smaller.siteHeader--Brand, .is-Smaller.siteHeader--Yellow, .is-Smaller.siteHeader--Blue, .is-Smaller.siteHeader--Purple, .is-Smaller.siteHeader--Orange {
        text-align: left; } }
  .siteHeader--Brand {
    border-color: #E00990; }
    .siteHeader--Brand.is-Smaller {
      background: #E00990;
      color: #222; }
  .siteHeader--Yellow {
    border-color: #F8C51B; }
    .siteHeader--Yellow.is-Smaller {
      background: #F8C51B;
      color: #000; }
  .siteHeader--Blue {
    border-color: #0ACDEA; }
    .siteHeader--Blue.is-Smaller {
      background: #0ACDEA;
      color: #222; }
  .siteHeader--Purple {
    border-color: #8F74FF; }
    .siteHeader--Purple.is-Smaller {
      background: #8F74FF;
      color: #222; }
  .siteHeader--Orange {
    border-color: #EC6350; }
    .siteHeader--Orange.is-Smaller {
      background: #EC6350;
      color: #222; }
  .siteHeader__CurrentArticle {
    text-transform: uppercase;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-size: 0.75em;
    font-weight: 700;
    color: #FFF;
    line-height: 74px;
    margin: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: 0ms; }
    .siteHeader__CurrentArticle strong {
      font-weight: 900;
      color: #000; }
      .siteHeader__CurrentArticle strong:after {
        content: "\2014";
        margin: 0 10px;
        vertical-align: 1px; }
    .siteHeader__CurrentArticle.is-Hidden {
      opacity: 0 !important; }
    .is-Smaller .siteHeader__CurrentArticle {
      position: relative;
      opacity: 1;
      visibility: visible;
      transition: 300ms 100ms; }
    @media screen and (max-width: 768px) {
      .siteHeader__CurrentArticle {
        display: none; } }

.logo {
  display: inline-block;
  margin: 28px 0 10px 0;
  line-height: 30px;
  fill: currentColor;
  width: 120px;
  height: auto;
  z-index: 20; }
  .is-Smaller .logo {
    position: absolute;
    bottom: 11px;
    left: 20px;
    animation: 600ms logoAppear; }
    @media screen and (max-width: 768px) {
      .is-Smaller .logo {
        display: none; } }
  .logo__Image, .logo__Image--White {
    display: inline-block; }
    .is-Smaller .logo__Image, .is-Smaller .logo__Image--White {
      display: none; }
    .logo__Image--White {
      display: none; }
      .is-Smaller .logo__Image--White {
        display: inline-block; }

.nav, .nav--Brand, .nav--Yellow, .nav--Blue, .nav--Purple, .nav--Orange, .nav--Single {
  text-transform: uppercase;
  font-family: "brandon-grotesque", Arial, sans-serif;
  overflow-x: auto;
  white-space: nowrap;
  margin: 0 80px;
  transition: transform 300ms; }
  .nav ul, .nav--Brand ul, .nav--Yellow ul, .nav--Blue ul, .nav--Purple ul, .nav--Orange ul, .nav--Single ul {
    margin: 0;
    padding: 0;
    font-size: 0; }
  .nav li, .nav--Brand li, .nav--Yellow li, .nav--Blue li, .nav--Purple li, .nav--Orange li, .nav--Single li {
    font-size: 16px;
    display: inline-block;
    vertical-align: top; }
  .nav li:after, .nav--Brand li:after, .nav--Yellow li:after, .nav--Blue li:after, .nav--Purple li:after, .nav--Orange li:after, .nav--Single li:after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    vertical-align: middle;
    margin-top: 1px; }
  .nav li:last-child:after, .nav--Brand li:last-child:after, .nav--Yellow li:last-child:after, .nav--Blue li:last-child:after, .nav--Purple li:last-child:after, .nav--Orange li:last-child:after, .nav--Single li:last-child:after {
    display: none; }
  .nav li.current-menu-item a, .nav--Brand li.current-menu-item a, .nav--Yellow li.current-menu-item a, .nav--Blue li.current-menu-item a, .nav--Purple li.current-menu-item a, .nav--Orange li.current-menu-item a, .nav--Single li.current-menu-item a {
    position: relative; }
    .nav li.current-menu-item a:before, .nav--Brand li.current-menu-item a:before, .nav--Yellow li.current-menu-item a:before, .nav--Blue li.current-menu-item a:before, .nav--Purple li.current-menu-item a:before, .nav--Orange li.current-menu-item a:before, .nav--Single li.current-menu-item a:before {
      content: "";
      display: block;
      height: 3px;
      background: #E00990;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 1px;
      z-index: -1;
      left: 20px;
      right: 20px; }
  .nav a, .nav--Brand a, .nav--Yellow a, .nav--Blue a, .nav--Purple a, .nav--Orange a, .nav--Single a {
    padding: 0 20px;
    display: inline-block;
    text-decoration: none;
    font-size: 0.75em;
    font-weight: 900;
    color: inherit;
    line-height: 75px;
    vertical-align: middle; }
    .nav a:hover, .nav--Brand a:hover, .nav--Yellow a:hover, .nav--Blue a:hover, .nav--Purple a:hover, .nav--Orange a:hover, .nav--Single a:hover {
      color: #E00990;
      fill: #E00990; }
    .is-Smaller .nav a:hover, .is-Smaller .nav--Brand a:hover, .is-Smaller .nav--Yellow a:hover, .is-Smaller .nav--Blue a:hover, .is-Smaller .nav--Purple a:hover, .is-Smaller .nav--Orange a:hover, .is-Smaller .nav--Single a:hover {
      color: #FFF; }
  .nav--Brand li.current-menu-item a:before {
    background: #E00990; }
  .nav--Yellow li.current-menu-item a:before {
    background: #F8C51B; }
  .nav--Blue li.current-menu-item a:before {
    background: #0ACDEA; }
  .nav--Purple li.current-menu-item a:before {
    background: #8F74FF; }
  .nav--Orange li.current-menu-item a:before {
    background: #EC6350; }
  @media screen and (max-width: 768px) {
    .is-Smaller .nav, .is-Smaller .nav--Brand, .is-Smaller .nav--Yellow, .is-Smaller .nav--Blue, .is-Smaller .nav--Purple, .is-Smaller .nav--Orange, .is-Smaller .nav--Single {
      transform: translateX(-80px);
      margin-right: 0; } }
  @media screen and (max-width: 480px) {
    .is-Smaller .nav, .is-Smaller .nav--Brand, .is-Smaller .nav--Yellow, .is-Smaller .nav--Blue, .is-Smaller .nav--Purple, .is-Smaller .nav--Orange, .is-Smaller .nav--Single {
      margin-left: 0;
      margin-right: 80px;
      transform: none; } }
  @media screen and (max-width: 480px) {
    .nav, .nav--Brand, .nav--Yellow, .nav--Blue, .nav--Purple, .nav--Orange, .nav--Single {
      margin-left: 0;
      margin-right: 80px;
      transform: none; } }
  .is-Smaller .nav--Single {
    display: none; }

.sideNav, .sideNav--Social {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1010;
  font-family: "brandon-grotesque", Arial, sans-serif;
  margin-top: -5px; }
  .sideNav svg, .sideNav--Social svg {
    width: 25px;
    height: 25px;
    transition: 200ms;
    vertical-align: middle;
    fill: inherit; }
  .sideNav label, .sideNav--Social label, .sideNav a, .sideNav--Social a {
    cursor: pointer;
    padding: 0 25px;
    display: inline-block; }
    .sideNav label:hover, .sideNav--Social label:hover, .sideNav a:hover, .sideNav--Social a:hover {
      fill: #E00990; }
      .sideNav label:hover svg, .sideNav--Social label:hover svg, .sideNav a:hover svg, .sideNav--Social a:hover svg {
        fill: inherit; }
    .is-Smaller .sideNav label:hover, .is-Smaller .sideNav--Social label:hover, .is-Smaller .sideNav a:hover, .is-Smaller .sideNav--Social a:hover {
      fill: #FFF; }
  .sideNav input[type="checkbox"], .sideNav--Social input[type="checkbox"] {
    display: none; }
    .sideNav input[type="checkbox"]:checked ~ ul, .sideNav--Social input[type="checkbox"]:checked ~ ul {
      visibility: visible;
      opacity: 1; }
      .sideNav input[type="checkbox"]:checked ~ ul li, .sideNav--Social input[type="checkbox"]:checked ~ ul li {
        transform: translateY(0%);
        opacity: 1; }
  .sideNav > ul, .sideNav--Social > ul {
    margin: 0;
    padding: 0; }
    .sideNav > ul > li, .sideNav--Social > ul > li {
      line-height: 75px; }
      .is-Smaller .sideNav > ul > li, .is-Smaller .sideNav--Social > ul > li {
        line-height: 80px; }
        .is-Smaller .sideNav > ul > li.search, .is-Smaller .sideNav--Social > ul > li.search {
          display: none; }
      .sideNav > ul > li > label, .sideNav--Social > ul > li > label {
        transition: background 200ms; }
        .is-Smaller .sideNav > ul > li > label, .is-Smaller .sideNav--Social > ul > li > label {
          background: #222;
          fill: #FFF; }
          .is-Smaller .sideNav > ul > li > label:hover, .is-Smaller .sideNav--Social > ul > li > label:hover {
            fill: #E00990; }
    .sideNav > ul ul, .sideNav--Social > ul ul {
      visibility: hidden;
      opacity: 0;
      transition: 500ms cubic-bezier(0.86, 0, 0.07, 1);
      position: fixed;
      text-align: left;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background: linear-gradient(45deg, #E00990, #0ACDEA);
      margin: 0;
      padding: 0;
      overflow: hidden; }
      .sideNav > ul ul:before, .sideNav--Social > ul ul:before {
        content: "";
        display: block;
        position: fixed;
        top: -100%;
        right: -100%;
        bottom: -100%;
        left: -100%;
        background: #E00990;
        background: linear-gradient(45deg, #E00990, #E00990, #0ACDEA, #0ACDEA);
        z-index: -1;
        animation: 10s spinMenu infinite linear; }
      .sideNav > ul ul li, .sideNav--Social > ul ul li {
        line-height: 30px;
        transition: 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 100ms;
        transform: translateY(50%);
        opacity: 0; }
        .sideNav > ul ul li:nth-child(1), .sideNav--Social > ul ul li:nth-child(1) {
          transition: 800ms 150ms cubic-bezier(0.215, 0.61, 0.355, 1); }
        .sideNav > ul ul li:nth-child(2), .sideNav--Social > ul ul li:nth-child(2) {
          transition: 800ms 200ms cubic-bezier(0.215, 0.61, 0.355, 1); }
        .sideNav > ul ul li:nth-child(3), .sideNav--Social > ul ul li:nth-child(3) {
          transition: 800ms 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
        .sideNav > ul ul li:nth-child(4), .sideNav--Social > ul ul li:nth-child(4) {
          transition: 800ms 300ms cubic-bezier(0.215, 0.61, 0.355, 1); }
        .sideNav > ul ul li:nth-child(5), .sideNav--Social > ul ul li:nth-child(5) {
          transition: 800ms 350ms cubic-bezier(0.215, 0.61, 0.355, 1); }
        .sideNav > ul ul li:nth-child(6), .sideNav--Social > ul ul li:nth-child(6) {
          transition: 800ms 400ms cubic-bezier(0.215, 0.61, 0.355, 1); }
        .sideNav > ul ul li:nth-child(7), .sideNav--Social > ul ul li:nth-child(7) {
          transition: 800ms 450ms cubic-bezier(0.215, 0.61, 0.355, 1); }
        .sideNav > ul ul li:nth-child(8), .sideNav--Social > ul ul li:nth-child(8) {
          transition: 800ms 500ms cubic-bezier(0.215, 0.61, 0.355, 1); }
        .sideNav > ul ul li:nth-child(9), .sideNav--Social > ul ul li:nth-child(9) {
          transition: 800ms 550ms cubic-bezier(0.215, 0.61, 0.355, 1); }
        .sideNav > ul ul li:nth-child(10), .sideNav--Social > ul ul li:nth-child(10) {
          transition: 800ms 600ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .sideNav > ul ul a, .sideNav--Social > ul ul a, .sideNav > ul ul label, .sideNav--Social > ul ul label {
        color: #FFF;
        font-size: 4em;
        line-height: 1.3;
        font-weight: 500;
        text-decoration: none;
        padding: 10px 40px;
        font-size: 4vw; }
        .sideNav > ul ul a:hover, .sideNav--Social > ul ul a:hover, .sideNav > ul ul label:hover, .sideNav--Social > ul ul label:hover {
          color: #222; }
        @media screen and (max-width: 960px) {
          .sideNav > ul ul a, .sideNav--Social > ul ul a, .sideNav > ul ul label, .sideNav--Social > ul ul label {
            font-size: 6vw; } }
        @media screen and (max-width: 768px) {
          .sideNav > ul ul a, .sideNav--Social > ul ul a, .sideNav > ul ul label, .sideNav--Social > ul ul label {
            font-size: 8vw; } }
      .sideNav > ul ul a, .sideNav--Social > ul ul a {
        display: block; }
      .sideNav > ul ul label, .sideNav--Social > ul ul label {
        padding-top: 25px;
        margin-bottom: 20px;
        fill: #222;
        margin-top: -100px; }
        .sideNav > ul ul label:hover, .sideNav--Social > ul ul label:hover {
          fill: #FFF; }
  .sideNav li, .sideNav--Social li {
    display: block; }
  .sideNav__Search form {
    position: relative; }
    .sideNav__Search form:before {
      height: 0.4vw;
      content: "";
      display: block;
      background: #000;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 5vw;
      z-index: -1;
      width: 42vw; }
  .sideNav__Search input {
    font-size: 5vw;
    background: transparent;
    font-weight: 700;
    border: 0;
    text-align: center;
    color: #FFF;
    width: 42vw;
    margin: 0 auto;
    display: block; }
    .sideNav__Search input::-webkit-input-placeholder {
      color: #FFF; }
    .sideNav__Search input::-moz-placeholder {
      color: #FFF; }
    .sideNav__Search input:-moz-placeholder {
      color: #FFF; }
    .sideNav__Search input:-ms-input-placeholder {
      color: #FFF; }
  .sideNav__Search button {
    display: none; }
  .sideNav__Search p {
    line-height: 1;
    font-family: "freight-text-pro", Georgia, serif;
    margin: 0;
    text-align: center; }
  .sideNav--Social {
    right: auto;
    left: 0;
    z-index: 1000; }
    .sideNav--Social a {
      padding: 0 15px;
      display: inline-block; }
    .sideNav--Social svg {
      width: 30px;
      height: 30px;
      fill: #bebebe; }
    .is-Smaller .sideNav--Social {
      opacity: 0; }
    @media screen and (max-width: 480px) {
      .sideNav--Social {
        display: none; } }

@keyframes spinner {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.smallArticles {
  margin: 30px 0 20px 0; }
  @media screen and (max-width: 768px) {
    .smallArticles {
      overflow-x: auto; } }
  .smallArticles__Wrapper {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    display: flex;
    justify-content: center; }
    .smallArticles__Wrapper::after {
      clear: both;
      content: "";
      display: table; }
    @media screen and (max-width: 768px) {
      .smallArticles__Wrapper {
        width: 170%; } }
    @media screen and (max-width: 480px) {
      .smallArticles__Wrapper {
        width: 250%; } }

.smallArticle, .smallArticle--Brand, .smallArticle--Yellow, .smallArticle--Blue, .smallArticle--Purple, .smallArticle--Orange {
  float: left;
  display: block;
  margin-right: 3.44828%;
  width: 31.03448%;
  padding: 15px;
  position: relative;
  z-index: 1;
  text-align: center;
  overflow: hidden;
  background-color: #FFF;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-blend-mode: luminosity;
  background-position: center;
  background-size: cover; }
  .smallArticle:last-child, .smallArticle--Brand:last-child, .smallArticle--Yellow:last-child, .smallArticle--Blue:last-child, .smallArticle--Purple:last-child, .smallArticle--Orange:last-child {
    margin-right: 0; }
  .smallArticle:before, .smallArticle--Brand:before, .smallArticle--Yellow:before, .smallArticle--Blue:before, .smallArticle--Purple:before, .smallArticle--Orange:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(to right, #FFF, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8)); }
  .smallArticle__Title {
    font-size: 1.125em;
    font-family: "brandon-grotesque", Arial, sans-serif;
    max-width: 220px;
    margin: 0 auto;
    line-height: 1.2; }
    .smallArticle__Title a {
      display: block;
      text-decoration: none;
      color: inherit; }
      .smallArticle__Title a:before, .smallArticle__Title a:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: 400ms cubic-bezier(0.86, 0, 0.07, 1); }
      .smallArticle__Title a:before {
        border-bottom: 5px solid #E00990;
        z-index: 0; }
      .smallArticle__Title a:after {
        background: #E00990;
        z-index: -1;
        transform: translateY(100%);
        border-bottom: 5px solid #FFF; }
      .smallArticle__Title a:hover:before {
        border-color: #FFF; }
      .smallArticle__Title a:hover:after {
        transform: translateY(0); }
    .smallArticle__Title span {
      line-height: 20px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent -3px, transparent 13px, #FFF 13px, #FFF 17px);
      display: inline; }
    .smallArticle__Title:hover + .smallArticle__Category a, .smallArticle__Title:hover + .smallArticle__Category--Brand a, .smallArticle__Title:hover + .smallArticle__Category--Yellow a, .smallArticle__Title:hover + .smallArticle__Category--Blue a, .smallArticle__Title:hover + .smallArticle__Category--Purple a, .smallArticle__Title:hover + .smallArticle__Category--Orange a {
      color: rgba(0, 0, 0, 0.8); }
  .smallArticle__Category, .smallArticle__Category--Brand, .smallArticle__Category--Yellow, .smallArticle__Category--Blue, .smallArticle__Category--Purple, .smallArticle__Category--Orange {
    color: #E00990;
    margin: 5px 0;
    display: inline-block; }
    .smallArticle__Category ul, .smallArticle__Category--Brand ul, .smallArticle__Category--Yellow ul, .smallArticle__Category--Blue ul, .smallArticle__Category--Purple ul, .smallArticle__Category--Orange ul {
      margin: 0;
      padding: 0;
      font-size: 0; }
    .smallArticle__Category li, .smallArticle__Category--Brand li, .smallArticle__Category--Yellow li, .smallArticle__Category--Blue li, .smallArticle__Category--Purple li, .smallArticle__Category--Orange li {
      font-size: 16px;
      display: inline-block;
      vertical-align: top; }
    .smallArticle__Category a, .smallArticle__Category--Brand a, .smallArticle__Category--Yellow a, .smallArticle__Category--Blue a, .smallArticle__Category--Purple a, .smallArticle__Category--Orange a {
      color: inherit;
      text-transform: uppercase;
      font-family: "brandon-grotesque", Arial, sans-serif;
      font-size: 0.5625em;
      font-weight: 700;
      text-decoration: none;
      display: block;
      transition: 300ms; }
    .smallArticle__Category--Brand {
      color: #E00990; }
    .smallArticle__Category--Yellow {
      color: #F8C51B; }
    .smallArticle__Category--Blue {
      color: #0ACDEA; }
    .smallArticle__Category--Purple {
      color: #8F74FF; }
    .smallArticle__Category--Orange {
      color: #EC6350; }
  .smallArticle--Brand a:before {
    border-color: #E00990; }
  .smallArticle--Brand a:after {
    background: #E00990; }
  .smallArticle--Yellow a:before {
    border-color: #F8C51B; }
  .smallArticle--Yellow a:after {
    background: #F8C51B; }
  .smallArticle--Blue a:before {
    border-color: #0ACDEA; }
  .smallArticle--Blue a:after {
    background: #0ACDEA; }
  .smallArticle--Purple a:before {
    border-color: #8F74FF; }
  .smallArticle--Purple a:after {
    background: #8F74FF; }
  .smallArticle--Orange a:before {
    border-color: #EC6350; }
  .smallArticle--Orange a:after {
    background: #EC6350; }

.slider {
  background: transparent;
  margin-top: 40px;
  width: 100%;
  overflow: hidden; }
  .slider__Wrapper, .slider__Wrapper--Featured {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px; }
    .slider__Wrapper::after, .slider__Wrapper--Featured::after {
      clear: both;
      content: "";
      display: table; }
    .slider__Wrapper--Featured {
      max-width: 1160px;
      background: #E00990;
      padding: 0; }
  @media screen and (max-width: 768px) {
    .slider {
      margin: 5px 0; } }

.slide, .slide--with-Overlay {
  background: #FFF;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 60px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-blend-mode: multiply;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 100%; }
  .slide__Center {
    /* */ }
  .slide__Wrapper, .slide__Wrapper--Longreads {
    max-width: 530px; }
    .slide__Wrapper--Longreads {
      vertical-align: bottom;
      max-width: none;
      padding-bottom: 20px; }
  .slide__Category ul, .slide__Category--Brand ul, .slide__Category--Yellow ul, .slide__Category--Blue ul, .slide__Category--Purple ul, .slide__Category--Orange ul, .slide__Category--White ul, .slide__Category--without-Background ul, .slide__Category--Longreads ul {
    margin: 0;
    padding: 0;
    font-size: 0; }
  .slide__Category li, .slide__Category--Brand li, .slide__Category--Yellow li, .slide__Category--Blue li, .slide__Category--Purple li, .slide__Category--Orange li, .slide__Category--White li, .slide__Category--without-Background li, .slide__Category--Longreads li {
    font-size: 16px;
    display: inline-block;
    vertical-align: top; }
  .slide__Category a, .slide__Category--Brand a, .slide__Category--Yellow a, .slide__Category--Blue a, .slide__Category--Purple a, .slide__Category--Orange a, .slide__Category--White a, .slide__Category--without-Background a, .slide__Category--Longreads a {
    display: inline-block;
    padding: 8px 20px;
    color: #E00990;
    text-decoration: none;
    border: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-size: 16px;
    border-top: 0;
    border-bottom: 0;
    position: relative;
    overflow: hidden;
    background: #FFF;
    transition: 200ms;
    padding: 6px 15px;
    font-size: 0.75em;
    font-weight: 900; }
    .slide__Category a:hover, .slide__Category--Brand a:hover, .slide__Category--Yellow a:hover, .slide__Category--Blue a:hover, .slide__Category--Purple a:hover, .slide__Category--Orange a:hover, .slide__Category--White a:hover, .slide__Category--without-Background a:hover, .slide__Category--Longreads a:hover {
      color: #FFF;
      background: #E00990; }
  .slide__Category--Brand a {
    color: #E00990; }
    .slide__Category--Brand a:hover {
      background: #E00990;
      color: #FFF; }
  .slide__Category--Yellow a {
    color: #F8C51B; }
    .slide__Category--Yellow a:hover {
      background: #F8C51B;
      color: #FFF; }
  .slide__Category--Blue a {
    color: #0ACDEA; }
    .slide__Category--Blue a:hover {
      background: #0ACDEA;
      color: #FFF; }
  .slide__Category--Purple a {
    color: #8F74FF; }
    .slide__Category--Purple a:hover {
      background: #8F74FF;
      color: #FFF; }
  .slide__Category--Orange a {
    color: #EC6350; }
    .slide__Category--Orange a:hover {
      background: #EC6350;
      color: #FFF; }
  .slide__Category--White a {
    color: #FFF; }
    .slide__Category--White a:hover {
      background: #FFF;
      color: #000; }
  .slide__Category--without-Background a {
    background: transparent; }
  .slide__Category--Longreads a {
    background: transparent;
    color: #FFF;
    padding: 0; }
    .slide__Category--Longreads a:hover {
      color: #F8C51B;
      background: transparent; }
  .slide__Title {
    font-size: 3.875em;
    font-family: "brandon-grotesque", Arial, sans-serif;
    color: #FFF;
    margin: 0;
    line-height: 0.93548em;
    font-weight: 500; }
    .slide__Title a {
      text-decoration: none;
      color: inherit;
      transition: 200ms; }
      .slide__Title a:hover {
        color: #F8C51B; }
    .slide__Title span {
      line-height: 58px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 50px, #000 50px, #000 58px);
      display: inline; }
    @media screen and (max-width: 768px) {
      .slide__Title {
        font-size: 2.5em;
        line-height: 1; }
        .slide__Title span {
          line-height: 44px;
          position: relative;
          z-index: 1;
          display: inline-block;
          background: repeating-linear-gradient(to bottom, transparent 0px, transparent 40px, #000 40px, #000 44px);
          display: inline; } }
  .slide__Content {
    font-size: 1.5em;
    line-height: 1.2;
    color: #FFF;
    margin-top: 10px; }
    @media screen and (max-width: 768px) {
      .slide__Content {
        font-size: 1.125em; } }
  .slide--with-Overlay {
    position: relative;
    z-index: 1; }
    .slide--with-Overlay:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: -1; }
  @media screen and (max-width: 768px) {
    .slide, .slide--with-Overlay {
      padding: 30px;
      background-blend-mode: unset; } }

.blockArticles {
  margin: 0 0 40px; }
  .blockArticles__Wrapper {
    display: flex;
    flex-wrap: wrap; }
  .blockArticles__Title {
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-weight: 500;
    text-align: center; }
  .blockArticles--More {
    margin: 60px 0; }
  @media screen and (max-width: 768px) {
    .blockArticles {
      margin-bottom: 0; } }

@keyframes removeDashing {
  from {
    background-size: 100%; }
  to {
    background-size: 0%; } }

@keyframes addDashing {
  from {
    background-size: 0%; }
  to {
    background-size: 100%; } }

.blockArticle, .blockArticle--Narrow, .blockArticle--External, .blockArticle--Slide, .blockArticle--with-Overlay, .blockArticle--align-Right {
  text-align: center;
  float: left;
  display: block;
  margin-right: 3.44828%;
  width: 48.27586%;
  position: relative;
  padding: 0 20px;
  position: relative;
  background: #f9f9f9 center/cover no-repeat;
  overflow: hidden;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .blockArticle:last-child, .blockArticle--Narrow:last-child, .blockArticle--External:last-child, .blockArticle--Slide:last-child, .blockArticle--with-Overlay:last-child, .blockArticle--align-Right:last-child {
    margin-right: 0; }
  .blockArticle__Wrapper, .blockArticle__Wrapper--Bottom {
    display: block;
    padding: 60px 20px; }
    .blockArticle__Wrapper--Bottom {
      align-self: flex-end; }
  .blockArticle__Sup, .blockArticle__Sup--Slide {
    font-style: italic;
    font-size: 3em;
    margin: 0;
    line-height: 1; }
    .blockArticle__Sup--Slide {
      font-size: 2.5em; }
  .blockArticle__Title, .blockArticle__Title--Inline, .blockArticle__Title--Black, .blockArticle__Title--Slide, .blockArticle__Title--Brand, .blockArticle__Title--Yellow, .blockArticle__Title--Blue, .blockArticle__Title--Purple, .blockArticle__Title--Orange {
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-size: 2.25em;
    line-height: 1;
    margin: 0;
    max-width: 320px;
    margin: 0 auto;
    color: #FFF;
    font-weight: 500; }
    .blockArticle__Title a, .blockArticle__Title--Inline a, .blockArticle__Title--Black a, .blockArticle__Title--Slide a, .blockArticle__Title--Brand a, .blockArticle__Title--Yellow a, .blockArticle__Title--Blue a, .blockArticle__Title--Purple a, .blockArticle__Title--Orange a {
      text-decoration: none;
      color: inherit;
      display: inline-block; }
      .blockArticle__Title a:before, .blockArticle__Title--Inline a:before, .blockArticle__Title--Black a:before, .blockArticle__Title--Slide a:before, .blockArticle__Title--Brand a:before, .blockArticle__Title--Yellow a:before, .blockArticle__Title--Blue a:before, .blockArticle__Title--Purple a:before, .blockArticle__Title--Orange a:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        background: #000;
        opacity: 0;
        transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);
        transform: rotate(20deg) translateY(100%);
        transform-origin: left top; }
      .blockArticle__Title a:after, .blockArticle__Title--Inline a:after, .blockArticle__Title--Black a:after, .blockArticle__Title--Slide a:after, .blockArticle__Title--Brand a:after, .blockArticle__Title--Yellow a:after, .blockArticle__Title--Blue a:after, .blockArticle__Title--Purple a:after, .blockArticle__Title--Orange a:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
      .blockArticle__Title a:hover:before, .blockArticle__Title--Inline a:hover:before, .blockArticle__Title--Black a:hover:before, .blockArticle__Title--Slide a:hover:before, .blockArticle__Title--Brand a:hover:before, .blockArticle__Title--Yellow a:hover:before, .blockArticle__Title--Blue a:hover:before, .blockArticle__Title--Purple a:hover:before, .blockArticle__Title--Orange a:hover:before {
        opacity: 0.5;
        transform: rotate(45deg) translateY(0%); }
      .blockArticle__Title a:hover span, .blockArticle__Title--Inline a:hover span, .blockArticle__Title--Black a:hover span, .blockArticle__Title--Slide a:hover span, .blockArticle__Title--Brand a:hover span, .blockArticle__Title--Yellow a:hover span, .blockArticle__Title--Blue a:hover span, .blockArticle__Title--Purple a:hover span, .blockArticle__Title--Orange a:hover span {
        animation: 400ms removeDashing forwards linear; }
      .blockArticle__Title a span, .blockArticle__Title--Inline a span, .blockArticle__Title--Black a span, .blockArticle__Title--Slide a span, .blockArticle__Title--Brand a span, .blockArticle__Title--Yellow a span, .blockArticle__Title--Blue a span, .blockArticle__Title--Purple a span, .blockArticle__Title--Orange a span {
        line-height: 35px;
        position: relative;
        z-index: 1;
        display: inline-block;
        background: repeating-linear-gradient(to bottom, transparent -1px, transparent 28px, #000 28px, #000 34px);
        display: inline;
        animation: 400ms addDashing forwards linear; }
    .blockArticle__Title--Inline {
      display: inline-block; }
    .blockArticle__Title--Black {
      color: #000; }
      .blockArticle__Title--Black a:before {
        background: #F8C51B; }
      .blockArticle__Title--Black a:hover:before {
        opacity: 0.2; }
      .blockArticle__Title--Black a span {
        line-height: 35px;
        position: relative;
        z-index: 1;
        display: inline-block;
        background: repeating-linear-gradient(to bottom, transparent -1px, transparent 28px, #F8C51B 28px, #F8C51B 34px);
        display: inline; }
    .blockArticle__Title--Slide a {
      transition: 200ms; }
      .blockArticle__Title--Slide a:before {
        display: none; }
      .blockArticle__Title--Slide a:hover {
        color: #000; }
        .blockArticle__Title--Slide a:hover:before {
          opacity: 0.1; }
      .blockArticle__Title--Slide a span {
        background: transparent; }
    .blockArticle__Title--Brand a:before {
      background: #E00990; }
    .blockArticle__Title--Brand a span {
      line-height: 35px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent -1px, transparent 28px, #E00990 28px, #E00990 34px);
      display: inline; }
    .blockArticle__Title--Yellow a:before {
      background: #F8C51B; }
    .blockArticle__Title--Yellow a span {
      line-height: 35px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent -1px, transparent 28px, #F8C51B 28px, #F8C51B 34px);
      display: inline; }
    .blockArticle__Title--Blue a:before {
      background: #0ACDEA; }
    .blockArticle__Title--Blue a span {
      line-height: 35px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent -1px, transparent 28px, #0ACDEA 28px, #0ACDEA 34px);
      display: inline; }
    .blockArticle__Title--Purple a:before {
      background: #8F74FF; }
    .blockArticle__Title--Purple a span {
      line-height: 35px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent -1px, transparent 28px, #8F74FF 28px, #8F74FF 34px);
      display: inline; }
    .blockArticle__Title--Orange a:before {
      background: #EC6350; }
    .blockArticle__Title--Orange a span {
      line-height: 35px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent -1px, transparent 28px, #EC6350 28px, #EC6350 34px);
      display: inline; }
  .blockArticle__Category, .blockArticle__Category--background-White, .blockArticle__Category--color-Brand, .blockArticle__Category--color-Yellow, .blockArticle__Category--color-Blue, .blockArticle__Category--color-Purple, .blockArticle__Category--color-Orange {
    margin: 10px 0;
    position: relative;
    z-index: 10; }
    .blockArticle__Category ul, .blockArticle__Category--background-White ul, .blockArticle__Category--color-Brand ul, .blockArticle__Category--color-Yellow ul, .blockArticle__Category--color-Blue ul, .blockArticle__Category--color-Purple ul, .blockArticle__Category--color-Orange ul {
      margin: 0;
      padding: 0;
      font-size: 0; }
    .blockArticle__Category li, .blockArticle__Category--background-White li, .blockArticle__Category--color-Brand li, .blockArticle__Category--color-Yellow li, .blockArticle__Category--color-Blue li, .blockArticle__Category--color-Purple li, .blockArticle__Category--color-Orange li {
      font-size: 16px;
      display: inline-block;
      vertical-align: top; }
    .blockArticle__Category a, .blockArticle__Category--background-White a, .blockArticle__Category--color-Brand a, .blockArticle__Category--color-Yellow a, .blockArticle__Category--color-Blue a, .blockArticle__Category--color-Purple a, .blockArticle__Category--color-Orange a {
      display: inline-block;
      padding: 8px 20px;
      color: #FFF;
      text-decoration: none;
      border: 0;
      text-transform: uppercase;
      font-weight: 600;
      font-family: "brandon-grotesque", Arial, sans-serif;
      font-size: 16px;
      border-top: 0;
      border-bottom: 0;
      position: relative;
      overflow: hidden;
      background: transparent;
      transition: 200ms;
      font-size: 0.75em; }
      .blockArticle__Category a:hover, .blockArticle__Category--background-White a:hover, .blockArticle__Category--color-Brand a:hover, .blockArticle__Category--color-Yellow a:hover, .blockArticle__Category--color-Blue a:hover, .blockArticle__Category--color-Purple a:hover, .blockArticle__Category--color-Orange a:hover {
        color: #000;
        background: #FFF; }
    .blockArticle__Category--background-White a {
      background: #FFF;
      color: #222; }
      .blockArticle__Category--background-White a:hover {
        color: #E00990; }
    .blockArticle__Category--color-Brand a {
      color: #E00990; }
      .blockArticle__Category--color-Brand a:hover {
        background: #E00990;
        color: #FFF; }
    .blockArticle__Category--color-Yellow a {
      color: #F8C51B; }
      .blockArticle__Category--color-Yellow a:hover {
        background: #F8C51B;
        color: #222; }
    .blockArticle__Category--color-Blue a {
      color: #0ACDEA; }
      .blockArticle__Category--color-Blue a:hover {
        background: #0ACDEA;
        color: #FFF; }
    .blockArticle__Category--color-Purple a {
      color: #8F74FF; }
      .blockArticle__Category--color-Purple a:hover {
        background: #8F74FF;
        color: #FFF; }
    .blockArticle__Category--color-Orange a {
      color: #EC6350; }
      .blockArticle__Category--color-Orange a:hover {
        background: #EC6350;
        color: #FFF; }
  .blockArticle__Lead {
    font-size: 1.25em;
    color: #FFF;
    display: inline-block;
    max-width: 360px; }
  .blockArticle--Narrow, .blockArticle--External {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 31.03448%;
    display: flex;
    float: none;
    padding: 6% 20px 10% 20px; }
    .blockArticle--Narrow:last-child, .blockArticle--External:last-child {
      margin-right: 0; }
    .blockArticle--Narrow:nth-child(3n), .blockArticle--External:nth-child(3n) {
      margin-right: 0; }
    .blockArticle--Narrow:nth-child(3n+1), .blockArticle--External:nth-child(3n+1) {
      clear: left; }
  .blockArticle--External {
    padding-top: 10%; }
  .blockArticle--Slide {
    width: 33.33333%;
    float: left;
    background: transparent;
    margin: 0;
    padding: 0 10px 20px; }
  .blockArticle--with-Overlay {
    position: relative;
    z-index: 1; }
    .blockArticle--with-Overlay:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: -1; }
  .blockArticle--align-Right {
    text-align: right; }
  @media screen and (max-width: 768px) {
    .blockArticle, .blockArticle--Narrow, .blockArticle--External, .blockArticle--Slide, .blockArticle--with-Overlay, .blockArticle--align-Right {
      width: 100%;
      margin: 5px 0; } }

.knockout {
  margin: 0;
  position: relative;
  width: 100%;
  height: 200px; }
  .knockout__Layer {
    width: 100%;
    height: 100%;
    font-size: 18.75em;
    text-transform: uppercase;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-weight: 700; }
  .knockout__Image {
    position: absolute; }

.staffPicks {
  margin: 5% 0;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px; }
  .staffPicks::after {
    clear: both;
    content: "";
    display: table; }
  .staffPicks__Title, .staffPicks__Title--Quote {
    text-transform: uppercase;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-size: 0.75em;
    font-weight: 900;
    padding-top: 40px;
    margin: 0; }
    .staffPicks__Title--Quote:before {
      content: "\201C";
      font-size: 11.25em;
      line-height: 50px;
      margin-right: 10px;
      vertical-align: top; }
  .staffPicks__Content {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 65.51724%; }
    .staffPicks__Content:last-child {
      margin-right: 0; }
  .staffPicks__Lead {
    font-size: 3.75em;
    line-height: 1;
    font-family: "brandon-grotesque", Arial, sans-serif; }
    .staffPicks__Lead p {
      margin-top: 20px; }
  .staffPicks__Aside {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 22.41379%;
    float: right;
    margin-top: 40px;
    margin-right: 0; }
    .staffPicks__Aside:last-child {
      margin-right: 0; }
  .staffPicks__AsideImage img {
    border-radius: 50%;
    display: block; }
  .staffPicks__AsideTitle {
    font-family: "brandon-grotesque", Arial, sans-serif;
    margin-top: 20px;
    margin-bottom: 0; }
  .staffPicks__AsideDescription p {
    margin-top: 5px; }

.categoryArticles__Wrapper {
  display: flex;
  flex-wrap: wrap; }

@media screen and (max-width: 768px) {
  .categoryArticles {
    margin-bottom: 10px; } }

.categoryArticle, .categoryArticle--Blue {
  float: left;
  display: block;
  margin-right: 3.44828%;
  width: 22.41379%;
  position: relative;
  z-index: 1;
  overflow: hidden; }
  .categoryArticle:last-child, .categoryArticle--Blue:last-child {
    margin-right: 0; }
  .categoryArticle__Category, .categoryArticle__Category--Brand, .categoryArticle__Category--Yellow, .categoryArticle__Category--Blue, .categoryArticle__Category--Purple, .categoryArticle__Category--Orange {
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-weight: 400;
    text-align: center;
    margin: 0;
    margin: 10px 0 30px 0;
    display: block; }
    .categoryArticle__Category a, .categoryArticle__Category--Brand a, .categoryArticle__Category--Yellow a, .categoryArticle__Category--Blue a, .categoryArticle__Category--Purple a, .categoryArticle__Category--Orange a {
      text-decoration: none;
      color: inherit;
      font-size: 1.75em;
      padding: 10px 0;
      display: block; }
      .categoryArticle__Category a:hover span, .categoryArticle__Category--Brand a:hover span, .categoryArticle__Category--Yellow a:hover span, .categoryArticle__Category--Blue a:hover span, .categoryArticle__Category--Purple a:hover span, .categoryArticle__Category--Orange a:hover span {
        background: transparent; }
    .categoryArticle__Category span, .categoryArticle__Category--Brand span, .categoryArticle__Category--Yellow span, .categoryArticle__Category--Blue span, .categoryArticle__Category--Purple span, .categoryArticle__Category--Orange span {
      line-height: 30px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 28px, #E00990 28px, #E00990 30px);
      display: inline; }
    .categoryArticle__Category--Brand span {
      line-height: 30px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 28px, #E00990 28px, #E00990 30px);
      display: inline; }
    .categoryArticle__Category--Yellow span {
      line-height: 30px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 28px, #F8C51B 28px, #F8C51B 30px);
      display: inline; }
    .categoryArticle__Category--Blue span {
      line-height: 30px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 28px, #0ACDEA 28px, #0ACDEA 30px);
      display: inline; }
    .categoryArticle__Category--Purple span {
      line-height: 30px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 28px, #8F74FF 28px, #8F74FF 30px);
      display: inline; }
    .categoryArticle__Category--Orange span {
      line-height: 30px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 28px, #EC6350 28px, #EC6350 30px);
      display: inline; }
    @media screen and (max-width: 768px) {
      .categoryArticle__Category, .categoryArticle__Category--Brand, .categoryArticle__Category--Yellow, .categoryArticle__Category--Blue, .categoryArticle__Category--Purple, .categoryArticle__Category--Orange {
        margin: 0;
        margin-top: 10px; } }
  .categoryArticle__Image {
    padding-bottom: 60%;
    position: relative;
    overflow: hidden; }
    .categoryArticle__Image img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: auto; }
  .categoryArticle__Link, .categoryArticle__Link--Brand, .categoryArticle__Link--Yellow, .categoryArticle__Link--Blue, .categoryArticle__Link--Purple, .categoryArticle__Link--Orange {
    text-decoration: none;
    color: inherit;
    display: block; }
    .categoryArticle__Link:before, .categoryArticle__Link--Brand:before, .categoryArticle__Link--Yellow:before, .categoryArticle__Link--Blue:before, .categoryArticle__Link--Purple:before, .categoryArticle__Link--Orange:before {
      content: "";
      display: block;
      background: #E00990;
      position: absolute;
      top: -5px;
      height: 100%;
      width: 100%;
      transition: 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
      transform: translateY(100%);
      z-index: -1; }
    .categoryArticle__Link:hover, .categoryArticle__Link--Brand:hover, .categoryArticle__Link--Yellow:hover, .categoryArticle__Link--Blue:hover, .categoryArticle__Link--Purple:hover, .categoryArticle__Link--Orange:hover {
      color: #FFF;
      border-color: #FFF; }
      .categoryArticle__Link:hover:before, .categoryArticle__Link--Brand:hover:before, .categoryArticle__Link--Yellow:hover:before, .categoryArticle__Link--Blue:hover:before, .categoryArticle__Link--Purple:hover:before, .categoryArticle__Link--Orange:hover:before {
        transform: translateY(50%); }
    .categoryArticle__Link--Brand {
      border-color: #E00990; }
      .categoryArticle__Link--Brand:before {
        background: #E00990; }
    .categoryArticle__Link--Yellow {
      border-color: #F8C51B; }
      .categoryArticle__Link--Yellow:before {
        background: #F8C51B; }
      .categoryArticle__Link--Yellow:hover {
        color: #000; }
    .categoryArticle__Link--Blue {
      border-color: #0ACDEA; }
      .categoryArticle__Link--Blue:before {
        background: #0ACDEA; }
    .categoryArticle__Link--Purple {
      border-color: #8F74FF; }
      .categoryArticle__Link--Purple:before {
        background: #8F74FF; }
    .categoryArticle__Link--Orange {
      border-color: #EC6350; }
      .categoryArticle__Link--Orange:before {
        background: #EC6350; }
  .categoryArticle__Title {
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.625em;
    padding: 0 20px 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    transition: 300ms 100ms; }
  @media screen and (max-width: 768px) {
    .categoryArticle, .categoryArticle--Blue {
      float: left;
      display: block;
      margin-right: 3.44828%;
      width: 48.27586%; }
      .categoryArticle:last-child, .categoryArticle--Blue:last-child {
        margin-right: 0; }
      .categoryArticle:nth-child(2n), .categoryArticle--Blue:nth-child(2n) {
        margin-right: 0; }
      .categoryArticle:nth-child(2n+1), .categoryArticle--Blue:nth-child(2n+1) {
        clear: left; } }
  @media screen and (max-width: 480px) {
    .categoryArticle, .categoryArticle--Blue {
      width: 100%; } }

.loadMore {
  text-align: center;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
  margin-bottom: 40px;
  position: relative; }
  .loadMore::after {
    clear: both;
    content: "";
    display: table; }
  .loadMore a {
    color: #000;
    display: block;
    text-decoration: none;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-size: 1.875em;
    text-align: center;
    font-weight: 700;
    padding: 30px;
    border: 3px solid #F8C51B;
    position: relative;
    z-index: 1;
    overflow: hidden; }
    .loadMore a:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      display: block;
      height: 4px;
      width: 120px;
      background: #F8C51B;
      z-index: -1;
      margin-left: -60px; }
    .loadMore a:before {
      content: "";
      display: block;
      background: #F8C51B;
      transform: rotate(-10deg) translateY(100%);
      transform-origin: right top;
      transition: 500ms cubic-bezier(0.86, 0, 0.07, 1);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .loadMore a:hover:before {
      transform: rotate(0deg) translateY(0%);
      z-index: -1;
      opacity: 1; }
  .loadMore span {
    font-family: "brandon-grotesque", Arial, sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
    font-weight: 700;
    font-size: 0; }
    .loadMore span:after {
      content: "";
      display: inline-block;
      width: 22px;
      height: 22px;
      border: 3px solid #E00990;
      border-right: 3px solid transparent;
      vertical-align: middle;
      border-radius: 50%;
      margin: 0 10px;
      margin-top: -5px;
      animation: 1s spinner infinite linear; }
  .loadMore.is-Visible a {
    color: transparent; }
    .loadMore.is-Visible a:before, .loadMore.is-Visible a:after {
      display: none; }

.article__Content, .article__Content--Prologue, .article__Content--prologue-Brand, .article__Content--prologue-Yellow, .article__Content--prologue-Blue, .article__Content--prologue-Purple, .article__Content--prologue-Orange, .article__Content--without-PaddingBottom, .breaker, .breaker--text-Center, .breaker--text-Right, .breaker--Dark, .breaker--Image, .error404__Wrapper {
  font-size: 20px; }
  .article__Content p, .article__Content--Prologue p, .article__Content--prologue-Brand p, .article__Content--prologue-Yellow p, .article__Content--prologue-Blue p, .article__Content--prologue-Purple p, .article__Content--prologue-Orange p, .article__Content--without-PaddingBottom p, .breaker p, .breaker--text-Center p, .breaker--text-Right p, .breaker--Dark p, .breaker--Image p, .error404__Wrapper p {
    margin: 20px 0; }
  .article__Content a, .article__Content--Prologue a, .article__Content--prologue-Brand a, .article__Content--prologue-Yellow a, .article__Content--prologue-Blue a, .article__Content--prologue-Purple a, .article__Content--prologue-Orange a, .article__Content--without-PaddingBottom a, .breaker a, .breaker--text-Center a, .breaker--text-Right a, .breaker--Dark a, .breaker--Image a, .error404__Wrapper a {
    color: #E00990;
    text-decoration: none; }
    .article__Content a:hover, .article__Content--Prologue a:hover, .article__Content--prologue-Brand a:hover, .article__Content--prologue-Yellow a:hover, .article__Content--prologue-Blue a:hover, .article__Content--prologue-Purple a:hover, .article__Content--prologue-Orange a:hover, .article__Content--without-PaddingBottom a:hover, .breaker a:hover, .breaker--text-Center a:hover, .breaker--text-Right a:hover, .breaker--Dark a:hover, .breaker--Image a:hover, .error404__Wrapper a:hover {
      text-decoration: underline; }
  .article__Content h2, .article__Content--Prologue h2, .article__Content--prologue-Brand h2, .article__Content--prologue-Yellow h2, .article__Content--prologue-Blue h2, .article__Content--prologue-Purple h2, .article__Content--prologue-Orange h2, .article__Content--without-PaddingBottom h2, .breaker h2, .breaker--text-Center h2, .breaker--text-Right h2, .breaker--Dark h2, .breaker--Image h2, .error404__Wrapper h2 {
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-size: 1.25em;
    color: #5a5a5a;
    font-weight: 500;
    margin: -60px 0 30px 0;
    line-height: 1;
    position: relative;
    padding-top: 100px;
    z-index: -1; }
    .article__Content h2:before, .article__Content--Prologue h2:before, .article__Content--prologue-Brand h2:before, .article__Content--prologue-Yellow h2:before, .article__Content--prologue-Blue h2:before, .article__Content--prologue-Purple h2:before, .article__Content--prologue-Orange h2:before, .article__Content--without-PaddingBottom h2:before, .breaker h2:before, .breaker--text-Center h2:before, .breaker--text-Right h2:before, .breaker--Dark h2:before, .breaker--Image h2:before, .error404__Wrapper h2:before {
      content: "";
      display: block;
      height: 3px;
      background: #E00990;
      width: 25px;
      display: inline-block;
      vertical-align: middle;
      margin-left: -37px;
      margin-right: 12px;
      margin-top: -5px; }
  .article__Content blockquote, .article__Content--Prologue blockquote, .article__Content--prologue-Brand blockquote, .article__Content--prologue-Yellow blockquote, .article__Content--prologue-Blue blockquote, .article__Content--prologue-Purple blockquote, .article__Content--prologue-Orange blockquote, .article__Content--without-PaddingBottom blockquote, .breaker blockquote, .breaker--text-Center blockquote, .breaker--text-Right blockquote, .breaker--Dark blockquote, .breaker--Image blockquote, .error404__Wrapper blockquote {
    font-size: 1.375em;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-style: italic;
    font-weight: 700;
    color: #5a5a5a;
    position: relative;
    line-height: 1.4;
    float: right;
    max-width: 460px;
    margin: 0 -140px 0 30px; }
    .article__Content blockquote:after, .article__Content--Prologue blockquote:after, .article__Content--prologue-Brand blockquote:after, .article__Content--prologue-Yellow blockquote:after, .article__Content--prologue-Blue blockquote:after, .article__Content--prologue-Purple blockquote:after, .article__Content--prologue-Orange blockquote:after, .article__Content--without-PaddingBottom blockquote:after, .breaker blockquote:after, .breaker--text-Center blockquote:after, .breaker--text-Right blockquote:after, .breaker--Dark blockquote:after, .breaker--Image blockquote:after, .error404__Wrapper blockquote:after {
      content: "”";
      font-family: "freight-text-pro", Georgia, serif;
      color: #8F74FF;
      position: absolute;
      top: -30px;
      left: 100%;
      font-size: 4.09091em; }
    .article__Content blockquote p, .article__Content--Prologue blockquote p, .article__Content--prologue-Brand blockquote p, .article__Content--prologue-Yellow blockquote p, .article__Content--prologue-Blue blockquote p, .article__Content--prologue-Purple blockquote p, .article__Content--prologue-Orange blockquote p, .article__Content--without-PaddingBottom blockquote p, .breaker blockquote p, .breaker--text-Center blockquote p, .breaker--text-Right blockquote p, .breaker--Dark blockquote p, .breaker--Image blockquote p, .error404__Wrapper blockquote p {
      margin-top: 0; }
    @media screen and (max-width: 1200px) {
      .article__Content blockquote, .article__Content--Prologue blockquote, .article__Content--prologue-Brand blockquote, .article__Content--prologue-Yellow blockquote, .article__Content--prologue-Blue blockquote, .article__Content--prologue-Purple blockquote, .article__Content--prologue-Orange blockquote, .article__Content--without-PaddingBottom blockquote, .breaker blockquote, .breaker--text-Center blockquote, .breaker--text-Right blockquote, .breaker--Dark blockquote, .breaker--Image blockquote, .error404__Wrapper blockquote {
        margin-right: 0;
        padding-right: 40px;
        font-size: 1.125em;
        float: none;
        margin-left: 0;
        max-width: none; }
        .article__Content blockquote:after, .article__Content--Prologue blockquote:after, .article__Content--prologue-Brand blockquote:after, .article__Content--prologue-Yellow blockquote:after, .article__Content--prologue-Blue blockquote:after, .article__Content--prologue-Purple blockquote:after, .article__Content--prologue-Orange blockquote:after, .article__Content--without-PaddingBottom blockquote:after, .breaker blockquote:after, .breaker--text-Center blockquote:after, .breaker--text-Right blockquote:after, .breaker--Dark blockquote:after, .breaker--Image blockquote:after, .error404__Wrapper blockquote:after {
          left: auto;
          right: 10px;
          top: -25px; } }
  .article__Content .alignleft, .article__Content--Prologue .alignleft, .article__Content--prologue-Brand .alignleft, .article__Content--prologue-Yellow .alignleft, .article__Content--prologue-Blue .alignleft, .article__Content--prologue-Purple .alignleft, .article__Content--prologue-Orange .alignleft, .article__Content--without-PaddingBottom .alignleft, .breaker .alignleft, .breaker--text-Center .alignleft, .breaker--text-Right .alignleft, .breaker--Dark .alignleft, .breaker--Image .alignleft, .error404__Wrapper .alignleft {
    float: left;
    margin-right: 20px;
    text-align: left; }
    .article__Content .alignleft .wp-caption-text, .article__Content--Prologue .alignleft .wp-caption-text, .article__Content--prologue-Brand .alignleft .wp-caption-text, .article__Content--prologue-Yellow .alignleft .wp-caption-text, .article__Content--prologue-Blue .alignleft .wp-caption-text, .article__Content--prologue-Purple .alignleft .wp-caption-text, .article__Content--prologue-Orange .alignleft .wp-caption-text, .article__Content--without-PaddingBottom .alignleft .wp-caption-text, .breaker .alignleft .wp-caption-text, .breaker--text-Center .alignleft .wp-caption-text, .breaker--text-Right .alignleft .wp-caption-text, .breaker--Dark .alignleft .wp-caption-text, .breaker--Image .alignleft .wp-caption-text, .error404__Wrapper .alignleft .wp-caption-text {
      position: absolute;
      right: 100%;
      top: 0;
      max-width: 210px;
      width: 100%;
      padding-right: 30px;
      text-align: right; }
  .article__Content .alignright, .article__Content--Prologue .alignright, .article__Content--prologue-Brand .alignright, .article__Content--prologue-Yellow .alignright, .article__Content--prologue-Blue .alignright, .article__Content--prologue-Purple .alignright, .article__Content--prologue-Orange .alignright, .article__Content--without-PaddingBottom .alignright, .breaker .alignright, .breaker--text-Center .alignright, .breaker--text-Right .alignright, .breaker--Dark .alignright, .breaker--Image .alignright, .error404__Wrapper .alignright {
    float: right;
    margin-left: 20px;
    text-align: right; }
    .article__Content .alignright .wp-caption-text, .article__Content--Prologue .alignright .wp-caption-text, .article__Content--prologue-Brand .alignright .wp-caption-text, .article__Content--prologue-Yellow .alignright .wp-caption-text, .article__Content--prologue-Blue .alignright .wp-caption-text, .article__Content--prologue-Purple .alignright .wp-caption-text, .article__Content--prologue-Orange .alignright .wp-caption-text, .article__Content--without-PaddingBottom .alignright .wp-caption-text, .breaker .alignright .wp-caption-text, .breaker--text-Center .alignright .wp-caption-text, .breaker--text-Right .alignright .wp-caption-text, .breaker--Dark .alignright .wp-caption-text, .breaker--Image .alignright .wp-caption-text, .error404__Wrapper .alignright .wp-caption-text {
      position: absolute;
      left: 100%;
      top: 0;
      max-width: 210px;
      width: 100%;
      padding-left: 30px;
      text-align: left; }
  .article__Content .aligncenter, .article__Content--Prologue .aligncenter, .article__Content--prologue-Brand .aligncenter, .article__Content--prologue-Yellow .aligncenter, .article__Content--prologue-Blue .aligncenter, .article__Content--prologue-Purple .aligncenter, .article__Content--prologue-Orange .aligncenter, .article__Content--without-PaddingBottom .aligncenter, .breaker .aligncenter, .breaker--text-Center .aligncenter, .breaker--text-Right .aligncenter, .breaker--Dark .aligncenter, .breaker--Image .aligncenter, .error404__Wrapper .aligncenter {
    text-align: center;
    margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .article__Content .alignleft, .article__Content--Prologue .alignleft, .article__Content--prologue-Brand .alignleft, .article__Content--prologue-Yellow .alignleft, .article__Content--prologue-Blue .alignleft, .article__Content--prologue-Purple .alignleft, .article__Content--prologue-Orange .alignleft, .article__Content--without-PaddingBottom .alignleft, .breaker .alignleft, .breaker--text-Center .alignleft, .breaker--text-Right .alignleft, .breaker--Dark .alignleft, .breaker--Image .alignleft, .error404__Wrapper .alignleft, .article__Content .alignright, .article__Content--Prologue .alignright, .article__Content--prologue-Brand .alignright, .article__Content--prologue-Yellow .alignright, .article__Content--prologue-Blue .alignright, .article__Content--prologue-Purple .alignright, .article__Content--prologue-Orange .alignright, .article__Content--without-PaddingBottom .alignright, .breaker .alignright, .breaker--text-Center .alignright, .breaker--text-Right .alignright, .breaker--Dark .alignright, .breaker--Image .alignright, .error404__Wrapper .alignright, .article__Content .aligncenter, .article__Content--Prologue .aligncenter, .article__Content--prologue-Brand .aligncenter, .article__Content--prologue-Yellow .aligncenter, .article__Content--prologue-Blue .aligncenter, .article__Content--prologue-Purple .aligncenter, .article__Content--prologue-Orange .aligncenter, .article__Content--without-PaddingBottom .aligncenter, .breaker .aligncenter, .breaker--text-Center .aligncenter, .breaker--text-Right .aligncenter, .breaker--Dark .aligncenter, .breaker--Image .aligncenter, .error404__Wrapper .aligncenter {
      float: none;
      margin: 0 auto; } }
  .article__Content .wp-caption, .article__Content--Prologue .wp-caption, .article__Content--prologue-Brand .wp-caption, .article__Content--prologue-Yellow .wp-caption, .article__Content--prologue-Blue .wp-caption, .article__Content--prologue-Purple .wp-caption, .article__Content--prologue-Orange .wp-caption, .article__Content--without-PaddingBottom .wp-caption, .breaker .wp-caption, .breaker--text-Center .wp-caption, .breaker--text-Right .wp-caption, .breaker--Dark .wp-caption, .breaker--Image .wp-caption, .error404__Wrapper .wp-caption {
    position: relative; }
    .article__Content .wp-caption .wp-caption-text, .article__Content--Prologue .wp-caption .wp-caption-text, .article__Content--prologue-Brand .wp-caption .wp-caption-text, .article__Content--prologue-Yellow .wp-caption .wp-caption-text, .article__Content--prologue-Blue .wp-caption .wp-caption-text, .article__Content--prologue-Purple .wp-caption .wp-caption-text, .article__Content--prologue-Orange .wp-caption .wp-caption-text, .article__Content--without-PaddingBottom .wp-caption .wp-caption-text, .breaker .wp-caption .wp-caption-text, .breaker--text-Center .wp-caption .wp-caption-text, .breaker--text-Right .wp-caption .wp-caption-text, .breaker--Dark .wp-caption .wp-caption-text, .breaker--Image .wp-caption .wp-caption-text, .error404__Wrapper .wp-caption .wp-caption-text {
      font-style: italic;
      font-size: 0.75em;
      margin: 0; }
      @media screen and (max-width: 1200px) {
        .article__Content .wp-caption .wp-caption-text, .article__Content--Prologue .wp-caption .wp-caption-text, .article__Content--prologue-Brand .wp-caption .wp-caption-text, .article__Content--prologue-Yellow .wp-caption .wp-caption-text, .article__Content--prologue-Blue .wp-caption .wp-caption-text, .article__Content--prologue-Purple .wp-caption .wp-caption-text, .article__Content--prologue-Orange .wp-caption .wp-caption-text, .article__Content--without-PaddingBottom .wp-caption .wp-caption-text, .breaker .wp-caption .wp-caption-text, .breaker--text-Center .wp-caption .wp-caption-text, .breaker--text-Right .wp-caption .wp-caption-text, .breaker--Dark .wp-caption .wp-caption-text, .breaker--Image .wp-caption .wp-caption-text, .error404__Wrapper .wp-caption .wp-caption-text {
          position: static;
          max-width: none;
          text-align: left;
          margin-bottom: 20px; } }
  @media screen and (max-width: 480px) {
    .article__Content, .article__Content--Prologue, .article__Content--prologue-Brand, .article__Content--prologue-Yellow, .article__Content--prologue-Blue, .article__Content--prologue-Purple, .article__Content--prologue-Orange, .article__Content--without-PaddingBottom, .breaker, .breaker--text-Center, .breaker--text-Right, .breaker--Dark, .breaker--Image, .error404__Wrapper {
      font-size: 18px; } }

.article {
  padding-top: 20px;
  margin-bottom: 50px; }
  .article__Ad {
    float: right;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px; }
    @media screen and (max-width: 768px) {
      .article__Ad {
        float: none;
        margin: 0; } }
  .article__Header, .article__Header--with-Image {
    text-align: center;
    position: relative;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    margin-bottom: 40px; }
    .article__Header::after, .article__Header--with-Image::after {
      clear: both;
      content: "";
      display: table; }
    @media screen and (max-width: 768px) {
      .article__Header, .article__Header--with-Image {
        margin-bottom: 10px; } }
    .article__Header--with-Image {
      margin: -20px auto 0 auto;
      padding: 20% 50px 50px;
      max-width: none;
      background-position: center bottom; }
  .article__Subtitle {
    margin-top: 30px;
    font-size: 13px;
    text-transform: uppercase;
    color: #676767;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-weight: 900; }
    .article__Subtitle strong {
      color: #000;
      font-weight: 900; }
  .article__Title, .article__Title--Brand, .article__Title--Yellow, .article__Title--Blue, .article__Title--Purple, .article__Title--Orange, .article__Title--with-Image, .article__Title--Page {
    font-size: 4em;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-weight: 500;
    max-width: 700px;
    margin: 0 auto 0 auto;
    line-height: 1;
    color: inherit; }
    .article__Title a, .article__Title--Brand a, .article__Title--Yellow a, .article__Title--Blue a, .article__Title--Purple a, .article__Title--Orange a, .article__Title--with-Image a, .article__Title--Page a {
      text-decoration: none;
      color: inherit;
      transition: 200ms; }
      .article__Title a:hover, .article__Title--Brand a:hover, .article__Title--Yellow a:hover, .article__Title--Blue a:hover, .article__Title--Purple a:hover, .article__Title--Orange a:hover, .article__Title--with-Image a:hover, .article__Title--Page a:hover {
        opacity: 0.8; }
    .article__Title span, .article__Title--Brand span, .article__Title--Yellow span, .article__Title--Blue span, .article__Title--Purple span, .article__Title--Orange span, .article__Title--with-Image span, .article__Title--Page span {
      line-height: 68px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 63px, #E00990 63px, #E00990 68px);
      display: inline; }
    .article__Title--Brand span {
      line-height: 68px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 63px, #E00990 63px, #E00990 68px);
      display: inline; }
    .article__Title--Yellow span {
      line-height: 68px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 63px, #F8C51B 63px, #F8C51B 68px);
      display: inline; }
    .article__Title--Blue span {
      line-height: 68px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 63px, #0ACDEA 63px, #0ACDEA 68px);
      display: inline; }
    .article__Title--Purple span {
      line-height: 68px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 63px, #8F74FF 63px, #8F74FF 68px);
      display: inline; }
    .article__Title--Orange span {
      line-height: 68px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: repeating-linear-gradient(to bottom, transparent 0px, transparent 63px, #EC6350 63px, #EC6350 68px);
      display: inline; }
    @media screen and (max-width: 768px) {
      .article__Title, .article__Title--Brand, .article__Title--Yellow, .article__Title--Blue, .article__Title--Purple, .article__Title--Orange, .article__Title--with-Image, .article__Title--Page {
        font-size: 2.5em;
        margin-bottom: 20px; }
        .article__Title span, .article__Title--Brand span, .article__Title--Yellow span, .article__Title--Blue span, .article__Title--Purple span, .article__Title--Orange span, .article__Title--with-Image span, .article__Title--Page span {
          background: transparent;
          line-height: 1; } }
    .article__Title--with-Image {
      max-width: none; }
      @media screen and (max-width: 768px) {
        .article__Title--with-Image {
          font-size: 12vw !important; } }
      .article__Title--with-Image span {
        background: transparent; }
    .article__Title--Page {
      margin: 0 auto 60px auto;
      font-size: 3.625em;
      font-weight: 700; }
      .article__Title--Page span {
        line-height: 62px;
        position: relative;
        z-index: 1;
        display: inline-block;
        background: repeating-linear-gradient(to bottom, transparent 0px, transparent 57px, #0ACDEA 57px, #0ACDEA 62px);
        display: inline; }
  .article__HeaderInfo {
    position: relative;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto; }
    .article__HeaderInfo::after {
      clear: both;
      content: "";
      display: table; }
  .article__Meta {
    margin: 0;
    padding: 0;
    margin: 10px auto;
    text-transform: uppercase;
    max-width: 800px;
    color: #9a9a9a; }
    .article__Meta li {
      font-size: 16px;
      display: inline-block;
      vertical-align: top; }
    .article__Meta li {
      font-family: "brandon-grotesque", Arial, sans-serif;
      font-weight: 900;
      font-size: 0.875em;
      margin: 0 10px; }
    .article__Meta a {
      color: inherit;
      text-transform: uppercase;
      text-decoration: none; }
      .article__Meta a:hover {
        text-decoration: underline; }
  .article__Lead {
    font-style: italic;
    font-size: 1.375em;
    width: 100%;
    max-width: 1000px;
    padding: 0 250px;
    margin: 30px auto 10px auto;
    line-height: 1.3; }
    @media screen and (max-width: 768px) {
      .article__Lead {
        padding: 0;
        margin-bottom: 30px; } }
    @media screen and (max-width: 480px) {
      .article__Lead {
        font-size: 1.125em;
        margin: 0; } }
  .article__Author {
    position: absolute;
    width: 30%;
    top: 0;
    left: 10px;
    text-align: left; }
    @media screen and (max-width: 768px) {
      .article__Author {
        position: static;
        float: left;
        display: block;
        margin-right: 3.44828%;
        width: 48.27586%; }
        .article__Author:last-child {
          margin-right: 0; } }
    @media screen and (max-width: 480px) {
      .article__Author {
        position: static;
        float: none;
        display: inline-block;
        width: auto; } }
  .article__AuthorImage {
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px; }
    .article__AuthorImage img {
      border-radius: 50%; }
    @media screen and (max-width: 480px) {
      .article__AuthorImage {
        display: none; } }
  .article__AuthorName {
    font-style: italic;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
    margin: 15px 0 20px 0; }
    .article__AuthorName a, .article__AuthorName strong {
      display: block;
      font-style: normal;
      font-weight: 700;
      font-family: "brandon-grotesque", Arial, sans-serif;
      text-decoration: none;
      font-size: 1.125em; }
    .article__AuthorName a {
      color: #E00990; }
      .article__AuthorName a:hover {
        color: #222; }
    @media screen and (max-width: 480px) {
      .article__AuthorName {
        font-size: 0; }
        .article__AuthorName a, .article__AuthorName strong {
          font-size: 18px; } }
  .article__Share, .article__Share--Menu {
    position: absolute;
    width: 30%;
    top: 0;
    right: 0;
    text-align: right;
    margin: 0;
    padding: 0;
    fill: #818181; }
    .article__Share li, .article__Share--Menu li {
      font-size: 16px;
      display: inline-block;
      vertical-align: top; }
    @media screen and (max-width: 768px) {
      .article__Share, .article__Share--Menu {
        position: static;
        float: left;
        display: block;
        margin-right: 3.44828%;
        width: 48.27586%;
        margin-right: 0;
        padding: 10px 0; }
        .article__Share:last-child, .article__Share--Menu:last-child {
          margin-right: 0; } }
    @media screen and (max-width: 480px) {
      .article__Share, .article__Share--Menu {
        display: none; } }
    .article__Share a, .article__Share--Menu a {
      width: 55px;
      height: 55px;
      line-height: 62px;
      border: 2px solid #cdcdcd;
      border-radius: 50%;
      display: block;
      text-align: center;
      margin: 0 10px;
      fill: inherit;
      transition: 200ms; }
      .article__Share a:hover, .article__Share--Menu a:hover {
        fill: #E00990;
        border-color: #E00990; }
    .article__Share svg, .article__Share--Menu svg {
      width: 22px;
      height: 22px;
      fill: inherit;
      vertical-align: 0; }
    .article__Share--Menu {
      position: fixed;
      z-index: 2000;
      right: 75px;
      opacity: 0;
      visibility: hidden;
      transition: 300ms;
      padding: 0; }
      .article__Share--Menu li {
        border: 0;
        fill: #FFF;
        width: 75px;
        height: 75px;
        line-height: 75px;
        text-align: center;
        position: relative; }
        .article__Share--Menu li:hover > a {
          background: #FFF;
          fill: #000; }
        .article__Share--Menu li:hover ul {
          opacity: 1;
          visibility: visible; }
      .article__Share--Menu a {
        border: 0;
        width: auto;
        height: auto;
        line-height: inherit;
        border-radius: 0;
        margin: 0;
        cursor: pointer; }
        .article__Share--Menu a:hover {
          fill: #000;
          background: #FFF; }
      .article__Share--Menu svg {
        vertical-align: -5px;
        width: 24px;
        height: 24px; }
      .article__Share--Menu ul {
        position: absolute;
        top: 75px;
        left: 0;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        transition: 300ms; }
        .article__Share--Menu ul a {
          background: #000; }
      .is-Visible .article__Share--Menu {
        opacity: 1;
        visibility: visible; }
      .article__Share--Menu.is-Hidden {
        display: none; }
  .article__Footer {
    text-align: center; }
  .article__Like {
    margin: 0 0 50px 0; }
  .article__Image {
    margin-bottom: -100px;
    position: relative;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 40%; }
    .article__Image img {
      display: none; }
    @media screen and (max-width: 768px) {
      .article__Image {
        padding: 0;
        height: 40vh; } }
  .article__Wrapper {
    position: relative; }
  .article__Intro {
    background: #FFF;
    max-width: 1180px;
    margin: 0 auto; }
  .article__Container, .article__Container--Dark, .article__Container--Breaker, .article__Container--Page {
    background: #FFF;
    position: relative;
    z-index: 10;
    margin-left: -30px;
    margin-right: 100px;
    padding: 60px 160px 30px 260px; }
    .article__Container--Dark {
      background: transparent;
      padding-top: 0; }
    .article__Container--Breaker {
      max-width: 1180px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 260px 0 230px; }
      .article__Container--Breaker::after {
        clear: both;
        content: "";
        display: table; }
    .article__Container--Page {
      padding: 60px 5%;
      max-width: 800px;
      margin: 0 auto;
      background: transparent; }
    @media screen and (max-width: 960px) {
      .article__Container, .article__Container--Dark, .article__Container--Breaker, .article__Container--Page {
        padding-left: 80px;
        padding-right: 80px;
        margin: 0; } }
    @media screen and (max-width: 768px) {
      .article__Container, .article__Container--Dark, .article__Container--Breaker, .article__Container--Page {
        padding: 50px;
        margin: 0; } }
    @media screen and (max-width: 480px) {
      .article__Container, .article__Container--Dark, .article__Container--Breaker, .article__Container--Page {
        padding: 10px;
        margin: 0; } }
  .article__TOC, .article__TOC--Brand, .article__TOC--Yellow, .article__TOC--Blue, .article__TOC--Purple, .article__TOC--Orange {
    position: absolute;
    left: 30px;
    margin-top: 35px;
    opacity: 1;
    visibility: visible;
    bottom: 0;
    top: 0; }
    .article__TOC ol, .article__TOC--Brand ol, .article__TOC--Yellow ol, .article__TOC--Blue ol, .article__TOC--Purple ol, .article__TOC--Orange ol {
      margin: 0;
      padding: 0;
      counter-reset: toc;
      padding: 10px 0;
      border-top: 2px solid #E00990;
      border-bottom: 2px solid #E00990;
      width: 160px;
      margin-top: 40px;
      margin-bottom: 100px; }
    .article__TOC li, .article__TOC--Brand li, .article__TOC--Yellow li, .article__TOC--Blue li, .article__TOC--Purple li, .article__TOC--Orange li {
      display: block;
      counter-increment: toc; }
    .article__TOC a, .article__TOC--Brand a, .article__TOC--Yellow a, .article__TOC--Blue a, .article__TOC--Purple a, .article__TOC--Orange a {
      color: #9a9a9a;
      font-family: "brandon-grotesque", Arial, sans-serif;
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 0.75em;
      line-height: 1.4;
      position: relative;
      padding: 8px 0;
      display: block; }
      .article__TOC a:before, .article__TOC--Brand a:before, .article__TOC--Yellow a:before, .article__TOC--Blue a:before, .article__TOC--Purple a:before, .article__TOC--Orange a:before {
        content: counter(toc) ". ";
        color: #E00990;
        margin-right: 2px; }
      .article__TOC a:hover, .article__TOC--Brand a:hover, .article__TOC--Yellow a:hover, .article__TOC--Blue a:hover, .article__TOC--Purple a:hover, .article__TOC--Orange a:hover {
        color: #676767; }
    .article__TOC--Brand ol {
      border-color: #E00990; }
    .article__TOC--Brand a:before {
      color: #E00990; }
    .article__TOC--Yellow ol {
      border-color: #F8C51B; }
    .article__TOC--Yellow a:before {
      color: #F8C51B; }
    .article__TOC--Blue ol {
      border-color: #0ACDEA; }
    .article__TOC--Blue a:before {
      color: #0ACDEA; }
    .article__TOC--Purple ol {
      border-color: #8F74FF; }
    .article__TOC--Purple a:before {
      color: #8F74FF; }
    .article__TOC--Orange ol {
      border-color: #EC6350; }
    .article__TOC--Orange a:before {
      color: #EC6350; }
    @media screen and (max-width: 960px) {
      .article__TOC, .article__TOC--Brand, .article__TOC--Yellow, .article__TOC--Blue, .article__TOC--Purple, .article__TOC--Orange {
        display: none; } }
  .article__Content, .article__Content--Prologue, .article__Content--prologue-Brand, .article__Content--prologue-Yellow, .article__Content--prologue-Blue, .article__Content--prologue-Purple, .article__Content--prologue-Orange, .article__Content--without-PaddingBottom {
    padding-bottom: 20px; }
    .article__Content--Prologue > p:first-child {
      margin-top: 0; }
      .article__Content--Prologue > p:first-child:first-letter {
        font-size: 5em;
        font-family: "brandon-grotesque", Arial, sans-serif;
        float: left;
        line-height: 1;
        margin-right: 10px;
        font-weight: 500;
        margin-bottom: -10px;
        color: #E00990;
        line-height: 95px;
        position: relative;
        z-index: 1;
        display: inline-block;
        background: repeating-linear-gradient(to bottom, transparent -13px, transparent 76px, #000 76px, #000 82px);
        display: inline; }
    .article__Content--prologue-Brand {
      font-family: attr(data-font); }
      .article__Content--prologue-Brand > p:first-child {
        margin-top: 0; }
        .article__Content--prologue-Brand > p:first-child:first-letter {
          font-size: 5em;
          font-family: "brandon-grotesque", Arial, sans-serif;
          float: left;
          line-height: 1;
          margin-right: 10px;
          font-weight: 500;
          margin-bottom: -10px;
          color: #E00990;
          line-height: 95px;
          position: relative;
          z-index: 1;
          display: inline-block;
          background: repeating-linear-gradient(to bottom, transparent -13px, transparent 76px, #000 76px, #000 82px);
          display: inline; }
    .article__Content--prologue-Yellow {
      font-family: attr(data-font); }
      .article__Content--prologue-Yellow > p:first-child {
        margin-top: 0; }
        .article__Content--prologue-Yellow > p:first-child:first-letter {
          font-size: 5em;
          font-family: "brandon-grotesque", Arial, sans-serif;
          float: left;
          line-height: 1;
          margin-right: 10px;
          font-weight: 500;
          margin-bottom: -10px;
          color: #F8C51B;
          line-height: 95px;
          position: relative;
          z-index: 1;
          display: inline-block;
          background: repeating-linear-gradient(to bottom, transparent -13px, transparent 76px, #000 76px, #000 82px);
          display: inline; }
    .article__Content--prologue-Blue {
      font-family: attr(data-font); }
      .article__Content--prologue-Blue > p:first-child {
        margin-top: 0; }
        .article__Content--prologue-Blue > p:first-child:first-letter {
          font-size: 5em;
          font-family: "brandon-grotesque", Arial, sans-serif;
          float: left;
          line-height: 1;
          margin-right: 10px;
          font-weight: 500;
          margin-bottom: -10px;
          color: #0ACDEA;
          line-height: 95px;
          position: relative;
          z-index: 1;
          display: inline-block;
          background: repeating-linear-gradient(to bottom, transparent -13px, transparent 76px, #000 76px, #000 82px);
          display: inline; }
    .article__Content--prologue-Purple {
      font-family: attr(data-font); }
      .article__Content--prologue-Purple > p:first-child {
        margin-top: 0; }
        .article__Content--prologue-Purple > p:first-child:first-letter {
          font-size: 5em;
          font-family: "brandon-grotesque", Arial, sans-serif;
          float: left;
          line-height: 1;
          margin-right: 10px;
          font-weight: 500;
          margin-bottom: -10px;
          color: #8F74FF;
          line-height: 95px;
          position: relative;
          z-index: 1;
          display: inline-block;
          background: repeating-linear-gradient(to bottom, transparent -13px, transparent 76px, #000 76px, #000 82px);
          display: inline; }
    .article__Content--prologue-Orange {
      font-family: attr(data-font); }
      .article__Content--prologue-Orange > p:first-child {
        margin-top: 0; }
        .article__Content--prologue-Orange > p:first-child:first-letter {
          font-size: 5em;
          font-family: "brandon-grotesque", Arial, sans-serif;
          float: left;
          line-height: 1;
          margin-right: 10px;
          font-weight: 500;
          margin-bottom: -10px;
          color: #EC6350;
          line-height: 95px;
          position: relative;
          z-index: 1;
          display: inline-block;
          background: repeating-linear-gradient(to bottom, transparent -13px, transparent 76px, #000 76px, #000 82px);
          display: inline; }
    .article__Content--without-PaddingBottom {
      padding-bottom: 0; }

.breaker, .breaker--text-Center, .breaker--text-Right, .breaker--Dark, .breaker--Image {
  background: #FFF;
  padding: 40px 0;
  overflow: hidden; }
  .breaker--text-Center {
    text-align: center; }
  .breaker--text-Right {
    text-align: right; }
  .breaker--Dark {
    background: #222;
    color: #FFF; }
  .breaker--Image {
    background: #222;
    padding: 0; }
  @media screen and (max-width: 768px) {
    .breaker, .breaker--text-Center, .breaker--text-Right, .breaker--Dark, .breaker--Image {
      padding: 0; } }

.ruler {
  background: url(../images/logo_symbol.png) no-repeat center;
  border: 0;
  display: block;
  width: 50%;
  height: 30px;
  background-size: auto 100%;
  position: relative;
  margin: 50px auto; }
  .ruler:before, .ruler:after {
    content: "";
    display: block;
    height: 1px;
    width: 50%;
    background: #cdcdcd;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    right: 50%;
    margin-right: 40px; }
  .ruler:after {
    right: auto;
    left: 50%;
    margin-left: 40px;
    margin-right: 0; }

.error404 {
  background: #F1F1F1;
  display: flex;
  min-height: 100vh;
  min-height: calc(100vh - 150px);
  align-items: center;
  justify-content: center; }
  .error404__Wrapper {
    max-width: 600px;
    text-align: center; }
    .error404__Wrapper h1 {
      font-family: "brandon-grotesque", Arial, sans-serif; }

.embed {
  max-width: 800px;
  margin: 0 auto; }
  .embed__Wrapper {
    position: relative;
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    margin: 0 auto; }
  .embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.articleNav {
  text-align: center;
  border-bottom: 1px solid #ebebeb;
  background: #FFF;
  z-index: 1000;
  bottom: -1px;
  transition: 300ms;
  left: 0;
  right: 0;
  padding-top: 2px;
  transform: translateY(100%);
  position: fixed;
  opacity: 0;
  visibility: hidden; }
  @media screen and (max-width: 1200px) {
    .articleNav {
      left: 0;
      right: 0; } }
  .articleNav__Progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    border: none;
    appearance: none;
    color: #8F74FF;
    background: none;
    margin: 0;
    background: #d9d9d9; }
    .articleNav__Progress::-webkit-progress-bar {
      background: #d9d9d9; }
    .articleNav__Progress::-moz-progress-bar {
      background: #d9d9d9; }
    .articleNav__Progress::-webkit-progress-value {
      background: #8F74FF; }
  .articleNav ul, .articleNav ol {
    margin: 0;
    padding: 0 10px;
    white-space: nowrap;
    overflow-y: auto;
    counter-reset: toc-counter; }
  .articleNav li {
    display: inline-block;
    counter-increment: toc-counter; }
    .articleNav li:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      background: #d9d9d9;
      height: 1px;
      width: 30px;
      margin-left: 10px; }
    .articleNav li:first-child:before {
      display: none; }
    .articleNav li.current-menu-item a {
      color: #8F74FF;
      max-width: 500px; }
  .articleNav a {
    display: inline-block;
    text-decoration: none;
    padding: 0 20px;
    color: #b3b3b3;
    font-weight: 700;
    line-height: 60px;
    transition: all 200ms 200ms, max-width 400ms 400ms;
    font-family: "brandon-grotesque", Arial, sans-serif;
    text-transform: uppercase;
    font-size: 0.8125em;
    max-width: 40px;
    overflow: hidden;
    vertical-align: middle; }
    @media screen and (max-width: 960px) {
      .articleNav a {
        line-height: 50px; } }
    .articleNav a:before {
      content: counter(toc-counter, decimal-leading-zero);
      display: inline-block;
      margin-right: 10px; }
    .articleNav a:hover {
      color: #8F74FF;
      transition: all 200ms 0ms, max-width 400ms 400ms; }
      .articleNav a:hover i {
        color: inherit; }
  .is-Visible .articleNav {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible; }

.gallerySlider {
  overflow: hidden;
  background: #222; }
  .gallerySlider .flickity-viewport {
    padding-bottom: 50%;
    position: static; }
  .gallerySlider__List {
    margin: 0;
    padding: 0;
    max-height: 620px;
    position: relative; }
  .gallerySlider li {
    display: block;
    position: relative;
    z-index: 1; }
    .gallerySlider li:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      z-index: -1; }
    @media screen and (max-width: 768px) {
      .gallerySlider li {
        max-width: 100vw;
        width: 100%; } }
    .gallerySlider li:before {
      transition: 500ms; }
    .gallerySlider li.is-selected {
      position: relative;
      z-index: 1; }
      .gallerySlider li.is-selected:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        z-index: -1; }
  .gallerySlider img {
    max-width: none;
    vertical-align: middle;
    position: relative;
    z-index: -2; }
    @media screen and (max-width: 768px) {
      .gallerySlider img {
        width: 100%; } }

.searchResult {
  display: flex;
  margin-bottom: 40px;
  align-items: center; }
  .searchResult__Category ul {
    margin: 0;
    padding: 0;
    font-size: 0; }
  .searchResult__Category li {
    font-size: 16px;
    display: inline-block;
    vertical-align: top; }
  .searchResult__Category a {
    font-size: 12px;
    text-transform: uppercase;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-weight: 900;
    color: #EC6350;
    text-decoration: none; }
  .searchResult__Title {
    font-size: 42px;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-weight: 500;
    margin: 0;
    line-height: 1.2; }
    .searchResult__Title a {
      color: inherit;
      text-decoration: none; }
      .searchResult__Title a:hover {
        color: #EC6350; }
  .searchResult__Date {
    font-size: 12px;
    text-transform: uppercase;
    font-family: "brandon-grotesque", Arial, sans-serif;
    font-weight: 900;
    color: #B7B7B7;
    margin: 0; }
  .searchResult__Image {
    margin-right: 40px;
    width: 40%;
    max-width: 360px; }
    .searchResult__Image img {
      width: 100%;
      max-width: none; }
    @media screen and (max-width: 768px) {
      .searchResult__Image {
        display: none; } }
