.searchResult { display: flex; margin-bottom: 40px; align-items: center;
  &__Category { @include inlineNav;
    a { font-size: 12px; text-transform: uppercase; font-family: $title-font; font-weight: $ultra; color: $orange; text-decoration: none; }
  }
  &__Title { font-size: 42px; font-family: $title-font; font-weight: $mediumbold; margin: 0; line-height: 1.2; 
    a { color: inherit; text-decoration: none; 
      &:hover { color: $orange; }
    }
  }
  &__Date { font-size: 12px; text-transform: uppercase; font-family: $title-font; font-weight: $ultra; color: #B7B7B7; margin: 0; }
  &__Image { margin-right: 40px; width: 40%; max-width: 360px; 
    img { width: 100%; max-width: none; }

    @include media($tablet) { display: none; }
  }
}