@keyframes spinMenu {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes logoAppear {
  0%, 50% { transform: translateX(-50%); opacity: 0; }
  100% { transform: translateX(0%); opacity: 1; }
}

.siteHeader { position: absolute; z-index: 2000; top: 0; right: 0; left: 0; text-align: center; background: $white; transition: top 0ms, background 300ms; color: $dgray; border-top: 5px solid $brand;

  &__Wrapper { position: relative; min-height: 75px; }

  &__Ad { padding: 20px 0; display: none; 
    img { vertical-align: middle; }
    .is-Smaller & { display: none; }
    &.is-Visible { display: block; }
  }

  &.is-Smaller { position: fixed; top: -75px; background: $brand; color: $white; padding-top: 70px; 
    @include media($tablet) { text-align: left; }
  }

  @each $category, $color in $brand-colors {
    &--#{$category} { @extend .siteHeader; border-color: $color; 
      &.is-Smaller { background: $color; @if $color == $yellow { color: $black; } @else {color: $dgray; } }
    }
  }

  &__CurrentArticle { text-transform: uppercase; font-family: $title-font; font-size: em(12px); font-weight: $bold; color: $white; line-height: 74px; margin: 0; position: absolute; opacity: 0; visibility: hidden; transition: 0ms;
    strong { font-weight: $ultra; color: $black; 
      &:after { content: "\2014"; margin: 0 10px; vertical-align: 1px; }
    }

    &.is-Hidden { opacity: 0 !important; }
    .is-Smaller & { position: relative; opacity: 1; visibility: visible; transition: 300ms 100ms }
    @include media($tablet) { display: none; }
  }
}
.logo { display: inline-block; margin: 28px 0 10px 0; line-height: 30px; fill: currentColor; width: 120px; height: auto; z-index: 20;
  .is-Smaller & { position: absolute; bottom: 11px; left: 20px; animation: 600ms logoAppear; 
    @include media($tablet) { display: none; }
  }
  &__Image { display: inline-block;
    .is-Smaller & { display: none; }
    &--White { @extend .logo__Image; display: none; 
      .is-Smaller & { display: inline-block; }
    }
  }
}

.nav { @include inlineNav; text-transform: uppercase; font-family: $title-font; overflow-x: auto; white-space: nowrap; margin: 0 80px; transition: transform 300ms;
  li { 
    &:after { content: ""; display: inline-block; width: 1px; height: 15px; border-right: 1px solid alpha-color($black, 0.15); vertical-align: middle; margin-top: 1px; }
    &:last-child {
      &:after { display: none; }
    }
    &.current-menu-item {
      a { position: relative;
        &:before { content: ""; display: block; height: 3px; background: $brand; @include center(y); margin-top: 1px;	 z-index: -1; left: 20px; right: 20px; }
      }
    }
  }
  a { padding: 0 20px; display: inline-block; text-decoration: none; font-size: em(12px); font-weight: $ultra; color: inherit; line-height: 75px; vertical-align: middle;
    &:hover { color: $brand; fill: $brand; }
    .is-Smaller & {
      &:hover { color: $white; }
    }
  }

  @each $category, $color in $brand-colors {
    &--#{$category} { @extend .nav; 
      li {
        &.current-menu-item {
          a { 
            &:before { background: $color; }
          }
        }
      }
    }
  }

  .is-Smaller & { 
    @include media($tablet) { transform: translateX(-80px); margin-right: 0; }
    @include media($mobile) { margin-left: 0; margin-right: 80px; transform: none; }
  }
  @include media($mobile) { margin-left: 0; margin-right: 80px; transform: none; }

  &--Single { @extend .nav;
    .is-Smaller & {
      display: none;
    }
  }
}
.sideNav { position: absolute; right: 0; top: 0; z-index: 1010; font-family: $title-font; margin-top: -5px;

  // Globals
  svg { width: 25px; height: 25px; transition: 200ms; vertical-align: middle; fill: inherit; }
  label, a { cursor: pointer; padding: 0 25px; display: inline-block; 
    &:hover { fill: $brand; 
      svg { fill: inherit; }
    }
    .is-Smaller & {
      &:hover { fill: $white; }
    }
  }
  input[type="checkbox"] { display: none; 
    &:checked ~ {
      ul { visibility: visible; opacity: 1; 
        li { transform: translateY(0%); opacity: 1; }
      }
    }
  }

  & > ul { margin: 0; padding: 0; 

    & > li { line-height: 75px; 
      .is-Smaller & { line-height: 80px; 
        &.search { display: none; }
      }
      & > label { transition: background 200ms;
        .is-Smaller & { background: $dgray; fill: $white; 
          &:hover { fill: $brand; }
        }
      }
    }

    // Submenu
      ul { visibility: hidden; opacity: 0; transition: 500ms $ease-in-out-quint; position: fixed; text-align: left; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; flex-direction: column; justify-content: center; background: linear-gradient(45deg, $brand, $blue); margin: 0; padding: 0; overflow: hidden;
        &:before { content: ""; display: block; position: fixed; top: -100%; right: -100%; bottom: -100%; left: -100%; background: $brand; background: linear-gradient(45deg, $brand, $brand, $blue, $blue); z-index: -1; animation: 10s spinMenu infinite linear; }
        li { line-height: 30px; transition: 800ms $ease-out-cubic 100ms; transform: translateY(50%); opacity: 0; 
          @for $i from 1 through 10 { 
            &:nth-child(#{$i}) {
              transition: 800ms 100ms + 50ms*$i $ease-out-cubic; 
            }
          }
        }
        a, label { color: $white; font-size: em(64px); line-height: 1.3; font-weight: $mediumbold; text-decoration: none; padding: 10px 40px; font-size: 4vw;
          &:hover { color: $dgray; }

          @include media($tablet-wide) { font-size: 6vw; }
          @include media($tablet) { font-size: 8vw; }
        }
        a { display: block; }
        label { padding-top: 25px; margin-bottom: 20px; fill: $dgray; margin-top: -100px;
          &:hover { fill: $white; }
        }
      }
  }
  li { display: block; }

  &__Search { 
    form { position: relative;
      &:before { height: 0.4vw; content: ""; display: block; background: $black; @include center(x); top: 5vw; z-index: -1; width: 42vw; }


    }
    input { font-size: 5vw; background: transparent; font-weight: $bold; border: 0; text-align: center; color: $white; width: 42vw; margin: 0 auto; display: block;
      @include placeholder { color: $white; }
    }
    button { display: none; }
    p { line-height: 1; font-family: $serif; margin: 0; text-align: center; }
  }

  &--Social { @extend .sideNav; right: auto; left: 0; z-index: 1000;
    a { padding: 0 15px; display: inline-block; }
    svg { width: 30px; height: 30px; fill: darken($lgray, 20%); }

    .is-Smaller & { opacity: 0; }

    @include media($mobile) { display: none; }
  }
}