@keyframes spinner {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.smallArticles { margin: 30px 0 20px 0;
  @include media($tablet) { overflow-x: auto; }
  &__Wrapper { @include outer-container; padding: 0 10px; display: flex; justify-content: center;
    @include media($tablet) { width: 170%; }
    @include media($mobile) { width: 250%; }
  }
}
.smallArticle { @include span-columns(4); padding: 15px; position: relative; z-index: 1; text-align: center; overflow: hidden; background-color: $white; display: flex; align-items: center; flex-direction: column; justify-content: center;background-blend-mode: luminosity; background-position: center; background-size: cover;
  &:before { content: ""; display: block; position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: -1; background: linear-gradient(to right, $white, alpha-color($white, 0.9), alpha-color($white, 0.8)); }
  &__Title { font-size: em(18px); font-family: $title-font; max-width: 220px; margin: 0 auto; line-height: 1.2;
    a { display: block; text-decoration: none; color: inherit;
      &:before, &:after { content: ""; display: block; position: absolute; top: 0; right: 0; bottom: 0; left: 0; transition: 400ms $ease-in-out-quint; }
      &:before { border-bottom: 5px solid $brand; z-index: 0; }
      &:after { background: $brand; z-index: -1; transform: translateY(100%); border-bottom: 5px solid $white; }
      &:hover {
        &:before { border-color: $white; }
        &:after { transform: translateY(0); }
      }
    }

    span { @include dashing(20px, 4px, 3px); }

    &:hover + .smallArticle__Category {
      a { color: alpha-color($black, 0.8); }
    }
  }
  &__Category { color: $brand; margin: 5px 0; display: inline-block; @include inlineNav;
    a { color: inherit; text-transform: uppercase; font-family: $title-font; font-size: em(9px); font-weight: $bold; text-decoration: none; display: block; transition: 300ms; }

      @each $category, $color in $brand-colors {
        &--#{$category} { @extend .smallArticle__Category; color: $color; }
      }
  }

  // Extend .smallArticle
  @each $category, $color in $brand-colors {
    &--#{$category} { @extend .smallArticle;
      a {
        &:before { border-color: $color; }
        &:after { background: $color; }
      }
    }
  }
}

.slider { background: transparent; margin-top: 40px; width: 100%; overflow: hidden;
  &__Wrapper { @include outer-container; padding: 0 10px;
    &--Featured { @extend .slider__Wrapper; max-width: $max-width - 20; background: $brand; padding: 0; }
  }

  @include media($tablet) { margin: 5px 0; }
}
.slide { background: $white; background-size: cover; background-repeat: no-repeat; background-position: center; padding: 60px; position: relative; overflow: hidden; z-index: 1; background-blend-mode: multiply; width: 100%; display: flex; align-items: center; min-height: 100%;

  &__Center { /* */ }
  &__Wrapper { max-width: 530px;
    &--Longreads { @extend .slide__Wrapper; vertical-align: bottom; max-width: none; padding-bottom: 20px; }
  }
  &__Category { @include inlineNav;
    a { @include moreButton; padding: 6px 15px; font-size: em(12px); font-weight: $ultra; }
    // Extend .slide__Category
    @each $category, $color in $brand-colors {
      &--#{$category} { @extend .slide__Category;
        a { color: $color;
          &:hover { background: $color; color: $white, }
        }
      }
    }

    &--White { @extend .slide__Category;
      a { color: $white;
        &:hover { background: $white; color: $black, }
      }
    }

    &--without-Background { @extend .slide__Category;
      a { background: transparent; }
    }

    &--Longreads { @extend .slide__Category;
      a { background: transparent; color: $white; padding: 0;
        &:hover { color: $yellow; background: transparent; }
      }
    }
  }
  &__Title { font-size: em(62px); font-family: $title-font; color: $white; margin: 0; line-height: em(58px, 62px); font-weight: $mediumbold;
    a { text-decoration: none; color: inherit; transition: 200ms;
      &:hover { color: $yellow; }
    }
    span { @include dashing(58px, 8px, 0px, false, $black); }
    @include media($tablet) { font-size: em(40px); line-height: 1;
      span { @include dashing(44px, 4px, 0px, false, $black); }
    }
  }
  &__Content { font-size: $xxlarge; line-height: 1.2; color: $white; margin-top: 10px;
    @include media($tablet) { font-size: $large; }
  }

  &--with-Overlay { @extend .slide; @include overlay(0.5); }

  @include media($tablet) { padding: 30px; background-blend-mode: unset; }
}

.blockArticles { margin: 0 0 40px;
  &__Wrapper { @extend .center; display: flex; flex-wrap: wrap; }
  &__Title { font-family: $title-font; font-weight: $mediumbold; text-align: center; }
  &--More { margin: 60px 0; }
  @include media($tablet) { margin-bottom: 0; }
}

@keyframes removeDashing {
  from { background-size: 100%; }
  to { background-size: 0%; }
}

@keyframes addDashing {
  from { background-size: 0%; }
  to { background-size: 100%; }
}

.blockArticle { text-align: center; @include span-columns(6); position: relative; padding: 0 20px; position: relative; background: lighten($lgray, 3%) center / cover no-repeat; overflow: hidden; margin-top: 40px; display: flex; align-items: center; justify-content: center;
  &__Wrapper { display: block; padding: 60px 20px;
    &--Bottom { @extend .blockArticle__Wrapper; align-self: flex-end; }
  }
  &__Sup { font-style: italic; font-size: em(48px); margin: 0; line-height: 1;
    &--Slide { @extend .blockArticle__Sup; font-size: em(40px); }
  }

  &__Title { font-family: $title-font; font-size: em(36px); line-height: 1; margin: 0; max-width: 320px; margin: 0 auto; color: $white; font-weight: $mediumbold;
    $dh: 35px;
    $ds: 6px;
    $do: 1px;

    a { text-decoration: none; color: inherit; display: inline-block;
      &:before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 200%; height: 100%; background: $black; opacity: 0; transition: 400ms $ease-in-out-quint; transform: rotate(20deg) translateY(100%); transform-origin: left top; }
      &:after { content: ""; display: block; position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
      &:hover {
        &:before { opacity: 0.5; transform: rotate(45deg) translateY(0%); }
        span { animation: 400ms removeDashing forwards linear; }
      }
      span { @include dashing($dh, $ds, $do, false, $black); animation: 400ms addDashing forwards linear; }
    }

    &--Inline { @extend .blockArticle__Title; display: inline-block; }

    &--Black { @extend .blockArticle__Title; color: $black;
      a {
        &:before { background: $yellow; }
        &:hover {
          &:before { opacity: 0.2; }
        }
        span { @include dashing($dh, $ds, $do, false, $yellow); }
      }
    }

    &--Slide { @extend .blockArticle__Title;
      a { transition: 200ms;
        &:before { display: none; }
        &:hover { color: $black;
          &:before { opacity: 0.1; }
        }
        span { background: transparent; }
      }
    }

    @each $category, $color in $brand-colors {
      &--#{$category} { @extend .blockArticle__Title;
        a {
          &:before { background: $color; }
          span { @include dashing($dh, $ds, $do, false, $color); }
        }
      }
    }
  }

  &__Category { margin: 10px 0; position: relative; z-index: 10; @include inlineNav;
    a { @include moreButton($white, transparent, $black); font-size: $xsmall; }
    &--background-White { @extend .blockArticle__Category;
      a { background: $white; color: $dgray;
        &:hover { color: $brand; }
      }
    }
    @each $category, $color in $brand-colors {
      &--color-#{$category} { @extend .blockArticle__Category;
        a { color: $color;
          &:hover { background: $color; @if $color == $yellow { color: $dgray; } @else {color: $white; } }
        }
      }
    }
  }

  &__Lead { font-size: $xlarge; color: $white; display: inline-block; max-width: 360px; }

  &--Narrow { @extend .blockArticle; @include span-columns(4); @include omega(3n); display: flex; float: none; padding: 6% 20px 10% 20px; }
  &--External { @extend .blockArticle--Narrow; padding-top: 10%; }
  &--Slide { @extend .blockArticle; width: percent(1,3); float: left; background: transparent; margin: 0; padding: 0 10px 20px; }

  &--with-Overlay { @extend .blockArticle; @include overlay(0.5); }
  &--align-Right { @extend .blockArticle; text-align: right; }

  @include media($tablet) { @include fill-parent; margin: 5px 0; }
}

.knockout { margin: 0; position: relative; width: 100%; height: 200px;
  &__Layer { width: 100%; height: 100%; font-size: em(300px);text-transform: uppercase; font-family: $title-font; font-weight: $bold; }
  &__Image {  position: absolute; }
}

.staffPicks { margin: 5% 0; @include outer-container; padding: 0 30px;
  &__Title { text-transform: uppercase; font-family: $title-font; font-size: $xsmall; font-weight: $ultra; padding-top: 40px; margin: 0;
    &--Quote { @extend .staffPicks__Title;
      &:before { content: "\201C"; font-size: em(180px); line-height: 50px; margin-right: 10px; vertical-align: top; }
    }
  }

  &__Content { @include span-columns(8); }
    &__Lead { font-size: em(60px); line-height: 1; font-family: $title-font;
      p { margin-top: 20px; }
    }
  &__Aside { @include span-columns(3); float: right; margin-top: 40px; margin-right: 0; }
    &__AsideImage {
      img { border-radius: 50%; display: block; }
    }
    &__AsideTitle { font-family: $title-font; margin-top: 20px; margin-bottom: 0; }
    &__AsideDescription {
      p { margin-top: 5px; }
    }
}

.categoryArticles {
  &__Wrapper { @extend .center; display: flex; flex-wrap: wrap; }
  @include media($tablet) { margin-bottom: 10px; }
}

.categoryArticle { @include span-columns(3); position: relative; z-index: 1; overflow: hidden;
  &__Category { font-family: $title-font; font-weight: $regular; text-align: center; margin: 0; margin: 10px 0 30px 0; display: block;
    $dh: 30px;
    $ds: 2px;
    $do: 0px;

    a { text-decoration: none; color: inherit; font-size: em(28px); padding: 10px 0; display: block;
      &:hover {
        span { background: transparent; }
      }
    }
    span { @include dashing($dh, $ds, $do, false, $brand); }

    @each $category, $color in $brand-colors {
      &--#{$category} { @extend .categoryArticle__Category;
        span { @include dashing($dh, $ds, $do, false, $color); }
      }
    }

    @include media($tablet) { margin: 0; margin-top: 10px; }
  }

  &__Image { padding-bottom: 60%; position: relative; overflow: hidden;
    img { @include center; width: 100%; height: auto; }
  }

  &__Link { text-decoration: none; color: inherit; display: block;
    &:before { content: ""; display: block; background: $brand; position: absolute; top: -5px; height: 100%; width: 100%; transition: 400ms $ease-in-out-cubic; transform: translateY(100%); z-index: -1; }
    &:hover { color: $white; border-color: $white;
      &:before { transform: translateY(50%); }
    }

    @each $category, $color in $brand-colors {
      &--#{$category} { @extend .categoryArticle__Link; border-color: $color;
        &:before { background: $color; }
        @if $color == $yellow {
          &:hover { color: $black; }
        }
      }
    }
  }

  &__Title { font-family: $title-font; font-weight: $mediumbold; line-height: 1.2; font-size: em(26px); padding: 0 20px 20px; text-align: center; position: relative; z-index: 1; transition: 300ms 100ms; }

  &--Blue { @extend .categoryArticle; }

  @include media($tablet) { @include span-columns(6); @include omega(2n); }
  @include media($mobile) { @include fill-parent; }
}

.loadMore { text-align: center; @include outer-container; padding: 0 10px; margin-bottom: 40px; position: relative;
  a { color: $black; display: block; text-decoration: none; font-family: $title-font; font-size: em(30px); text-align: center; font-weight: $bold; padding: 30px; border: 3px solid $yellow; position: relative; z-index: 1; overflow: hidden;
    &:after { position: absolute; top: 50%; left: 50%; content: ""; display: block; height: 4px; width: 120px; background: $yellow; z-index: -1; margin-left: -60px; }
    &:before { content: ""; display: block; background: $yellow; transform: rotate(-10deg) translateY(100%); transform-origin: right top; transition: 500ms $ease-in-out-quint; position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
    &:hover {
      &:before { transform: rotate(0deg) translateY(0%); z-index: -1; opacity: 1; }
    }
  }

  span { font-family: $title-font; @include center; font-size: em(24px); font-weight: $bold; font-size: 0;
    &:after { content: ""; display: inline-block; width: 22px; height: 22px; border: 3px solid $brand; border-right: 3px solid transparent; vertical-align: middle; border-radius: 50%; margin: 0 10px; margin-top: -5px; animation: 1s spinner infinite linear; }
  }

  &.is-Visible {
    a { color: transparent;
      &:before, &:after { display: none; }
    }
  }
}
