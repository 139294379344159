// Change the grid settings
$column: 60px;
$gutter: 40px;
$grid-columns: 12;
$max-width: 1180px;
$site-max-width: 1680px;

$fg-column: $column;
$fg-gutter: $gutter;

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

// Define your breakpoints

// Desktop browsers
  $desktop-wide: new-breakpoint(max-width 1680px 12);
  $desktop: new-breakpoint(max-width 1440px 12);
  $desktop-narrow: new-breakpoint(max-width 1200px 12);

// Tablet browsers
  $tablet-wide: new-breakpoint(max-width 960px 12);
  $tablet: new-breakpoint(max-width 768px 12);

// Mobile browsers
  $mobile-wide: new-breakpoint(max-width 670px 12);
  $mobile: new-breakpoint(max-width 480px 12);
  $mobile-narrow: new-breakpoint(max-width 370px 12);