// BOWER
  // Normalize
  @import "../../assets/bower_components/normalize.css/normalize.css";

// BOURBON/NEAT
@import "bourbon";
@import "neat";

// CORE
@import "core/grid-settings";
@import "core/defaults";
@import "core/mixins";
@import "core/animations";

// PLUGINS
@import "../../assets/sass/plugins/animate.css";
@import "../../assets/sass/plugins/flickity.css";

// MODULES
@import "modules/sprite"; // SVG sprites
@import "modules/template"; // Main template file
@import "modules/slider"; // Slider options
    
  // Partials
  @import "partials/header";

  // Pages
  @import "modules/home"; // Home layout
  @import "modules/article"; // Single article layout
  @import "modules/forms"; // Forms helper
  @import "modules/search"; // Search results

// PARTIALS
