// Alpha color
@function alpha-color($color: $black, $opacity: 0.5) { 
  $alpha-color-value: rgba($color, $opacity); 
  @return $alpha-color-value;
}

// Align to center
@mixin center($axis: "both"){
  position:absolute;
  @if $axis == "y"{
    top:50%;
    transform: translateY(-50%);
  }
  @if $axis == "x"{
    left:50%;
    transform: translateX(-50%);
  }
  @if $axis == "both"{
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
}

// Target/context formula
@function percent($target, $context: $max-width) {
  $target-context-num: $target/$context * 100%;
  @return $target-context-num;
}

// EM formula
@function em($target, $context: $font-size) {
  $target-context-num: $target/$context * 1em;
  @return $target-context-num;
}

// Scollbars
@mixin scrollbars($size: 8px, $foreground-color: darken($white, 20%), $background-color: darken($white, 10%)) {
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 10px;
  }
}

// Elements
@mixin inlineNav($ul: true) {
  @if $ul == true {
    ul { margin: 0; padding: 0; font-size: 0; }
  } @else {
    margin: 0; padding: 0;
  }
  li { font-size: $font-size; display: inline-block; vertical-align: top; }
}

// More button
  @mixin moreButton($fgcolor: $brand, $bgcolor: $white, $fghover: $bgcolor, $bghover: $fgcolor) { display: inline-block; padding: 8px 20px; color: $fgcolor; text-decoration: none; border: 0; text-transform: uppercase; font-weight: $semibold; font-family: $title-font; font-size: 16px; border-top: 0; border-bottom: 0; position: relative; overflow: hidden; background: $bgcolor; transition: 200ms;

    &:hover { color: $fghover; background: $bghover; }
  }
  
// Overlay
  @mixin overlay($opacity: 0.5, $color: $black, $front: false, $element: before) { position: relative; z-index: 1;
    &:#{$element} { content: ""; display: block; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: alpha-color($color, $opacity); @if $front == true { z-index: 1; } @else { z-index: -1; } }
  }

// Text strikethrough gradient
@mixin dashing($lh: 20px, $sep: 3px, $offset: 1px, $animate: false, $color: $white, $hover: $black) { line-height: $lh; position: relative; z-index: 1; display: inline-block;

  @if $animate == false { 
    background: repeating-linear-gradient(to bottom, transparent 0px - $offset, transparent ($lh - $sep - $offset), $color ($lh - $sep - $offset), $color $lh - $offset); display: inline;
  }

  @if $animate == true {
    &:before, &:after { content: ""; display: block; position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: -1; transition: 200ms; background: repeating-linear-gradient(to bottom, transparent 0px - $offset, transparent ($lh - $sep - $offset), $color ($lh - $sep - $offset), $color $lh - $offset); }
    &:after { opacity: 0; background: repeating-linear-gradient(to bottom, transparent 0px - $offset, transparent ($lh - $sep - $offset), $hover ($lh - $sep - $offset), $hover $lh - $offset); }

    &:hover {
      &:before { opacity: 0; }
      &:after { opacity: 1; }
    }
  }
}