$icons: (
  sprite: (width: 2589px, height: 1958px, pngPath: '../images/sprite.png', svgPath: '../images/sprite.svg'),
  blog-article: (width: 30.648px, height: 38.07px, backgroundX: 0px, backgroundY: 0px),
    close: (width: 54px, height: 54px, backgroundX: -31px, backgroundY: -39px),
    facebook: (width: 430.113px, height: 430.114px, backgroundX: -85px, backgroundY: -93px),
    gallery: (width: 38.356px, height: 37.54px, backgroundX: -516px, backgroundY: -524px),
    logo: (width: 432.4000244140625px, height: 94.0999984741211px, backgroundX: -555px, backgroundY: -562px),
    mail: (width: 485.4110107421875px, height: 321.76300048828125px, backgroundX: -988px, backgroundY: -657px),
    menu: (width: 54px, height: 35.399993896484375px, backgroundX: -1474px, backgroundY: -979px),
    right: (width: 268.832px, height: 268.832px, backgroundX: -1528px, backgroundY: -1015px),
    search: (width: 48.69999694824219px, height: 50.100006103515625px, backgroundX: -1797px, backgroundY: -1284px),
    twitter: (width: 611.9990234375px, height: 497.1190185546875px, backgroundX: -1846px, backgroundY: -1335px),
    video: (width: 40.018px, height: 34.119px, backgroundX: -2458px, backgroundY: -1833px),
    youtube: (width: 90px, height: 90px, backgroundX: -2499px, backgroundY: -1868px),
  );

$sprite: map-get($icons, sprite) !default;
$baseFontSize: 16px !default;

// Gets an attribute from the sass map
@function sprite-attr($icon, $attr) {
  $newIcon: map-get($icons, $icon);
  @if $newIcon == null {
    @warn "Can't find an icon with the name #{$icon}";
  }
  @return map-get($newIcon, $attr);
}

@function icon-attr($icon) {
  $attr: (
    width: sprite-attr($icon, width),
    height: sprite-attr($icon, height),
    x: sprite-attr($icon, backgroundX),
    y: sprite-attr($icon, backgroundY)
    );
  @return $attr;
}

// Sets background image and size
%sprite { display: inline-block; vertical-align: middle; font-size: 0; font-style: normal; }

@mixin sprite($icon, $type: all) {
  $iconMap: icon-attr($icon);

  @extend %sprite;
  width: map-get($iconMap, width);
  height: auto;

  // Outputs background position in em
  @if $type != symbol {
    background-position: map-get($iconMap, x) map-get($iconMap, y);
    background-image: url(map-get($sprite, svgPath)); 
    background-size: map-get($sprite, width) map-get($sprite, height); 
  }
} 

svg.icon:not(.icon--full-color), symbol.icon:not(.icon--full-color) { 
  circle, ellipse, g, line, path, polygon, polyline, rect { fill: inherit; stroke: inherit; }
}

// .icon-blog-article { @include sprite(blog-article); }
.symbol-blog-article { @include sprite(blog-article, symbol); }
// .icon-close { @include sprite(close); }
.symbol-close { @include sprite(close, symbol); }
// .icon-facebook { @include sprite(facebook); }
.symbol-facebook { @include sprite(facebook, symbol); }
// .icon-gallery { @include sprite(gallery); }
.symbol-gallery { @include sprite(gallery, symbol); }
// .icon-logo { @include sprite(logo); }
.symbol-logo { @include sprite(logo, symbol); }
// .icon-mail { @include sprite(mail); }
.symbol-mail { @include sprite(mail, symbol); }
// .icon-menu { @include sprite(menu); }
.symbol-menu { @include sprite(menu, symbol); }
// .icon-right { @include sprite(right); }
.symbol-right { @include sprite(right, symbol); }
// .icon-search { @include sprite(search); }
.symbol-search { @include sprite(search, symbol); }
// .icon-twitter { @include sprite(twitter); }
.symbol-twitter { @include sprite(twitter, symbol); }
// .icon-video { @include sprite(video); }
.symbol-video { @include sprite(video, symbol); }
// .icon-youtube { @include sprite(youtube); }
.symbol-youtube { @include sprite(youtube, symbol); }
